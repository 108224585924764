import { getBar, getBarHistory } from 'app/services/graph/fetchers/bar'
import stringify from 'fast-json-stable-stringify'
import useSWR, { SWRConfiguration } from 'swr'
import { ChainId } from '@swapsicledex/swapsicle-sdk'
import { GraphProps } from '../interfaces'

// interface useBarProps {
//   variables?: { [key: string]: any }
//   chainId?: number
//   shouldFetch?: boolean
//   swrConfig?: SWRConfiguration
// }

export function useBar({
  chainId = ChainId.AVALANCHE,
  variables,
  shouldFetch = true,
  swrConfig = undefined,
}: GraphProps) {
  // @ts-ignore TYPE NEEDS FIXING
  const { data } = useSWR(
    shouldFetch ? ['bars', stringify(variables)] : null,
    () => getBar(variables, chainId),
    swrConfig
  )
  return data
}

// interface useBarHistoryProps {
//   variables?: { [key: string]: any }
//   chainId?: number
//   shouldFetch?: boolean
//   swrConfig?: SWRConfiguration
// }

export function useBarHistory(
  chainId = ChainId.AVALANCHE,
  variables,
  shouldFetch = true,
  swrConfig = undefined,) {
  const { data } = useSWR(
    shouldFetch ? ['barHistory', stringify(variables)] : null,
    // @ts-ignore TYPE NEEDS FIXING
    () => getBarHistory(variables, chainId),
    swrConfig
  )
  return data
}
