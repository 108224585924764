import gql from 'graphql-tag'

export const burnsQuery = gql`
  query burnsQuery($first: Int! = 1000, $skip: Int, $block: Block_height) {
    burns(first: $first, skip: $skip, orderBy: timestamp, orderDirection: asc, block: $block) {
        id
        amount
        timestamp
        blockNumber
    }
  }
`