export enum ChainSubdomain {
  ETHEREUM = 'ethereum',
  ROPSTEN = 'ropsten',
  RINKEBY = 'rinkeby',
  GÖRLI = 'gorli',
  KOVAN = 'kovan',
  POLYGON = 'polygon',
  FANTOM = 'fantom',
  GNOSIS = 'gnosis',
  BSC = 'bsc',
  ARBITRUM = 'arbitrum',
  AVALANCHE = 'avalanche',
  HECO = 'heco',
  HARMONY = 'harmony',
  OKEX = 'okex',
  CELO = 'celo',
  PALM = 'palm',
  MOONRIVER = 'moonriver',
  FUSE = 'fuse',
  TELOS = 'telos',
  TELOS_TESTNET = 'telos-testnet',
  MOONBEAM = 'moonbeam',
}
