import {
  ChainId,
  Currency,
  CurrencyAmount,
  JSBI,
  Price,
  Token,
  USD,
  AVALANCHE_TOKENS,
} from '@swapsicledex/swapsicle-sdk'
import { useEffect, useMemo, useState } from 'react'

import { useActiveWeb3React } from '../services/web3'
import { useV2TradeExactOut } from './useV2Trades'
import { useV2TradeExactOut as useV2TradeExactOutPortfolio } from './useV2TradesPortfolio'
import { useNativePrice } from 'app/services/graph'

// StableCoin amounts used when calculating spot price for a given currency.
// The amount is large enough to filter low liquidity pairs.
export const STABLECOIN_AMOUNT_OUT: { [chainId: number]: CurrencyAmount<Token> } = {
  [ChainId.ETHEREUM]: CurrencyAmount.fromRawAmount(USD[ChainId.ETHEREUM], 100_000e6),
  [ChainId.ROPSTEN]: CurrencyAmount.fromRawAmount(USD[ChainId.ROPSTEN], 100_000e6),
  [ChainId.KOVAN]: CurrencyAmount.fromRawAmount(USD[ChainId.KOVAN], 100_000e1),
  [ChainId.MATIC]: CurrencyAmount.fromRawAmount(USD[ChainId.MATIC], 100_000e6),
  [ChainId.FANTOM]: CurrencyAmount.fromRawAmount(USD[ChainId.FANTOM], 100_000e6),
  [ChainId.BSC]: CurrencyAmount.fromRawAmount(USD[ChainId.BSC], 100_000e18),
  [ChainId.HARMONY]: CurrencyAmount.fromRawAmount(USD[ChainId.HARMONY], 100_000e6),
  [ChainId.HECO]: CurrencyAmount.fromRawAmount(USD[ChainId.HECO], 100_000e6),
  [ChainId.OKEX]: CurrencyAmount.fromRawAmount(USD[ChainId.OKEX], 100_000e18),
  [ChainId.XDAI]: CurrencyAmount.fromRawAmount(USD[ChainId.XDAI], 100_000e6),
  [ChainId.ARBITRUM]: CurrencyAmount.fromRawAmount(USD[ChainId.ARBITRUM], 100_000e6),
  [ChainId.CELO]: CurrencyAmount.fromRawAmount(USD[ChainId.CELO], 100_000e18),
  [ChainId.MOONRIVER]: CurrencyAmount.fromRawAmount(USD[ChainId.MOONRIVER], 100_000e6),
  [ChainId.FUSE]: CurrencyAmount.fromRawAmount(USD[ChainId.FUSE], 100_000e6),
  [ChainId.MOONBEAM]: CurrencyAmount.fromRawAmount(USD[ChainId.MOONBEAM], 100_000e6),
  [ChainId.OPTIMISM]: CurrencyAmount.fromRawAmount(USD[ChainId.OPTIMISM], 100_000e6),

  [ChainId.TELOS]: CurrencyAmount.fromRawAmount(USD[ChainId.TELOS], 100_00e5),
  [ChainId.TELOS_TESTNET]: CurrencyAmount.fromRawAmount(USD[ChainId.TELOS_TESTNET], 100_00e5),
  [ChainId.AVALANCHE]: CurrencyAmount.fromRawAmount(USD[ChainId.AVALANCHE], 100_0e6),
}

/**
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
export default function useUSDCPrice(currency?: Currency): Price<Currency, Token> | undefined {
  const { chainId } = useActiveWeb3React()

  let amountOut = chainId ? STABLECOIN_AMOUNT_OUT[chainId] : undefined
  if (currency?.wrapped?.symbol == 'YUSD' || currency?.wrapped?.symbol == 'NRWL') {
    amountOut = CurrencyAmount.fromRawAmount(AVALANCHE_TOKENS.YUSD, 100e18)
  }
  const stablecoin = amountOut?.currency

  const v2USDCTrade = useV2TradeExactOut(currency, amountOut, {
    maxHops: 3,
  })

  return useMemo(() => {
    if (!currency || !stablecoin) {
      return undefined
    }

    // handle usdc
    if (currency?.wrapped.equals(stablecoin)) {
      return new Price(stablecoin, stablecoin, '1', '1')
    }

    // use v2 price if available
    if (v2USDCTrade) {
      const { numerator, denominator } = v2USDCTrade.route.midPrice
      // if (stablecoin.symbol == "YUSD") {
      //   console.log("test-", currency.symbol, stablecoin.symbol, "YUSD")
      //   return new Price(currency, USDCentre, JSBI.BigInt(6), JSBI.BigInt(6))
      // }
      // else {
      //   console.log("test-", currency.symbol, stablecoin.symbol, "USDC")
      return new Price(currency, stablecoin, denominator, numerator)
      // }
    }

    return undefined
  }, [currency, stablecoin, v2USDCTrade])
}

export function useUSDCPricePortfolio(currency?: Currency): Price<Currency, Token> | undefined {
  const { chainId } = useActiveWeb3React()

  let amountOut = chainId ? STABLECOIN_AMOUNT_OUT[chainId] : undefined
  if (currency?.wrapped?.symbol == 'YUSD' || currency?.wrapped?.symbol == 'NRWL') {
    amountOut = CurrencyAmount.fromRawAmount(AVALANCHE_TOKENS.YUSD, 100e18)
  }
  const stablecoin = amountOut?.currency

  const v2USDCTrade = useV2TradeExactOutPortfolio(currency, amountOut, {
    maxHops: 3,
  })

  return useMemo(() => {
    if (!currency || !stablecoin) {
      return undefined
    }

    // handle usdc
    if (currency?.wrapped.equals(stablecoin)) {
      return new Price(stablecoin, stablecoin, '1', '1')
    }

    // use v2 price if available
    if (v2USDCTrade) {
      const { numerator, denominator } = v2USDCTrade.route.midPrice
      // if (stablecoin.symbol == "YUSD") {
      //   console.log("test-", currency.symbol, stablecoin.symbol, "YUSD")
      //   return new Price(currency, USDCentre, JSBI.BigInt(6), JSBI.BigInt(6))
      // }
      // else {
      //   console.log("test-", currency.symbol, stablecoin.symbol, "USDC")
      return new Price(currency, stablecoin, denominator, numerator)
      // }
    }

    return undefined
  }, [currency, stablecoin, v2USDCTrade])
}

function useCoinGeckoRatio() {
  const { chainId } = useActiveWeb3React()

  const [coinGeckoPrice, setCoinGeckoPrice] = useState(null)
  const nativePrice = useNativePrice({ chainId })

  // console.log('Telos price:', coinGeckoPrice)
  // console.log('nativePrice', Number(nativePrice))

  const ratio = coinGeckoPrice ? Number(coinGeckoPrice) / Number(nativePrice) : 1
  //console.log('ratio', ratio)

  useEffect(() => {
    fetch('/api/telosPrice')
      .then((response) => response.json())
      .then((data) => {
        const price = data.price
        //console.log('cg data.price:', price)
        setCoinGeckoPrice(price)
      })
      .catch((error) => {
        console.log('Error:', error)
      })
  }, [])

  return ratio
}

export function useUSDCoinGeckoRatioValue() {
  const cgRatio = useCoinGeckoRatio()

  return useMemo(() => {
    if (!cgRatio) return null
    try {
      return cgRatio
    } catch (error) {
      return null
    }
  }, [cgRatio])
}

export function useUSDCValue(currencyAmount: CurrencyAmount<Currency> | undefined | null) {
  const price = useUSDCPrice(currencyAmount?.currency)

  return useMemo(() => {
    if (!price || !currencyAmount) return null
    try {
      return price.quote(currencyAmount)
    } catch (error) {
      return null
    }
  }, [currencyAmount, price])
}

export function useUSDCPriceWithLoadingIndicator(currency?: Currency) {
  const price = useUSDCPrice(currency)
  return useMemo(() => {
    if (!price || !currency) return { price: undefined, loading: false }
    try {
      return { price, loading: false }
    } catch (error) {
      return { price: undefined, loading: false }
    }
  }, [currency, price])
}

export function useUSDCValueWithLoadingIndicator(currencyAmount: CurrencyAmount<Currency> | undefined) {
  const price = useUSDCPrice(currencyAmount?.currency)
  return useMemo(() => {
    if (!price || !currencyAmount) return { value: undefined, loading: false }
    try {
      return { value: price.quote(currencyAmount), loading: false }
    } catch (error) {
      return { value: undefined, loading: false }
    }
  }, [currencyAmount, price])
}
