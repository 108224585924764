import { ChainId } from '@swapsicledex/swapsicle-sdk'
import { useActiveWeb3React } from 'app/services/web3'
import stringify from 'fast-json-stable-stringify'
import useSWR, { SWRConfiguration } from 'swr'

import {
  getAlcxPrice,
  getAvaxPrice,
  getBundle,
  getBurnedTokens,
  getCvxPrice,
  getDayData,
  getFactory,
  getFantomPrice,
  getSTLOSPrice,
  getFORTPrice,
  getLiquidityPositions,
  getUserLiquidityPositions,
  getMaticPrice,
  getMphPrice,
  getNativePrice,
  getPairDayData,
  getPairs,
  getPicklePrice,
  getSushiPrice,
  getTokenDayData,
  getTokenPairs,
  getTokens,
  getTruPrice,
  getYggPrice,
  getMarketData,
} from '../fetchers'
import { GraphProps } from '../interfaces'
import { ethPriceQuery } from '../queries'

export function useFactory({
  chainId = ChainId.ETHEREUM,
  variables,
  shouldFetch = true,
  swrConfig = undefined,
}: GraphProps) {
  const { data } = useSWR(
    shouldFetch ? ['factory', chainId, stringify(variables)] : null,
    // @ts-ignore TYPE NEEDS FIXING
    () => getFactory(chainId, variables),
    swrConfig
  )
  return data
}

export function useNativePrice({
  chainId = ChainId.ETHEREUM,
  variables,
  shouldFetch = true,
  swrConfig = undefined,
}: GraphProps) {
  const { data } = useSWR(
    shouldFetch ? ['nativePrice', chainId, stringify(variables)] : null,

    // @ts-ignore TYPE NEEDS FIXING
    () => getNativePrice(chainId, variables),
    swrConfig
  )
  return data
}

// @ts-ignore TYPE NEEDS FIXING
export function useEthPrice(variables = undefined, swrConfig: SWRConfiguration = undefined) {
  const { data } = useSWR(['ethPrice'], () => getNativePrice(variables), swrConfig)
  return data
}

// @ts-ignore TYPE NEEDS FIXING
export function useFantomPrice(swrConfig: SWRConfiguration = undefined) {
  const { chainId } = useActiveWeb3React()
  const shouldFetch = chainId && chainId === ChainId.FANTOM
  const { data } = useSWR(shouldFetch ? 'fantomPrice' : null, () => getFantomPrice(), swrConfig)
  return data
}

// @ts-ignore TYPE NEEDS FIXING
export function useSTLOSPrice(swrConfig: SWRConfiguration = undefined) {
  const { chainId } = useActiveWeb3React()
  const shouldFetch = (chainId && chainId === ChainId.TELOS) || chainId === ChainId.TELOS_TESTNET
  const { data } = useSWR(shouldFetch ? 'stlosPrice' : null, () => getSTLOSPrice(), swrConfig)
  return data
}

// @ts-ignore TYPE NEEDS FIXING
export function useFORTPrice(swrConfig: SWRConfiguration = undefined) {
  const { chainId } = useActiveWeb3React()
  const shouldFetch = (chainId && chainId === ChainId.TELOS) || chainId === ChainId.TELOS_TESTNET
  const { data } = useSWR(shouldFetch ? 'fortPrice' : null, () => getFORTPrice(), swrConfig)
  return data
}

// @ts-ignore TYPE NEEDS FIXING
export function useYggPrice(swrConfig: SWRConfiguration = undefined) {
  const { chainId } = useActiveWeb3React()
  const shouldFetch = chainId && chainId === ChainId.ETHEREUM
  const { data } = useSWR(shouldFetch ? ['yggPrice'] : null, () => getYggPrice(), swrConfig)
  return data
}

// @ts-ignore TYPE NEEDS FIXING
export function useTruPrice(swrConfig: SWRConfiguration = undefined) {
  const { chainId } = useActiveWeb3React()
  const { data } = useSWR(chainId && chainId === ChainId.ETHEREUM ? ['truPrice'] : null, () => getTruPrice(), swrConfig)
  return data
}

// @ts-ignore TYPE NEEDS FIXING
export function useAlcxPrice(swrConfig: SWRConfiguration = undefined) {
  const { chainId } = useActiveWeb3React()
  const shouldFetch = chainId && chainId === ChainId.ETHEREUM
  const { data } = useSWR(shouldFetch ? ['aclxPrice'] : null, () => getAlcxPrice(), swrConfig)
  return data
}

// @ts-ignore TYPE NEEDS FIXING
export function useCvxPrice(swrConfig: SWRConfiguration = undefined) {
  const { chainId } = useActiveWeb3React()
  const shouldFetch = chainId && chainId === ChainId.ETHEREUM
  const { data } = useSWR(shouldFetch ? ['cvxPrice'] : null, () => getCvxPrice(), swrConfig)
  return data
}

// @ts-ignore TYPE NEEDS FIXING
export function usePicklePrice(swrConfig: SWRConfiguration = undefined) {
  const { chainId } = useActiveWeb3React()
  const shouldFetch = chainId && chainId === ChainId.ETHEREUM
  const { data } = useSWR(shouldFetch ? ['picklePrice'] : null, () => getPicklePrice(), swrConfig)
  return data
}

// @ts-ignore TYPE NEEDS FIXING
export function useMphPrice(swrConfig: SWRConfiguration = undefined) {
  const { chainId } = useActiveWeb3React()
  const shouldFetch = chainId && chainId === ChainId.ETHEREUM
  const { data } = useSWR(shouldFetch ? ['mphPrice'] : null, () => getMphPrice(), swrConfig)
  return data
}

// @ts-ignore TYPE NEEDS FIXING
export function useAvaxPrice(swrConfig: SWRConfiguration = undefined) {
  const { data } = useSWR(['avaxPrice'], () => getAvaxPrice(), swrConfig)
  return data
}

// @ts-ignore TYPE NEEDS FIXING
export function useMaticPrice(swrConfig: SWRConfiguration = undefined) {
  const { data } = useSWR(['maticPrice'], () => getMaticPrice(), swrConfig)
  return data
}

// @ts-ignore TYPE NEEDS FIXING
export function useSushiPrice(swrConfig: SWRConfiguration = undefined) {
  const { data } = useSWR(['sushiPrice'], () => getSushiPrice(), swrConfig)
  return data
}

export function useMarketData(swrConfig: SWRConfiguration = undefined) {
  const { data } = useSWR(['marketData'], () => getMarketData(), swrConfig)
  return data
}

// @ts-ignore TYPE NEEDS FIXING
export function useBundle(variables = undefined, swrConfig: SWRConfiguration = undefined) {
  const { chainId } = useActiveWeb3React()
  const { data } = useSWR(chainId ? [chainId, ethPriceQuery, stringify(variables)] : null, () => getBundle(), swrConfig)
  return data
}

export function useLiquidityPositions({
  chainId = ChainId.ETHEREUM,
  variables,
  shouldFetch = true,
  swrConfig = undefined,
}: GraphProps) {
  const { data } = useSWR(
    shouldFetch ? ['liquidityPositions', chainId, stringify(variables)] : null,
    (_, chainId) => getLiquidityPositions(chainId, variables),
    swrConfig
  )
  return data
}

export function useUserLiquidityPositions({
  chainId = ChainId.ETHEREUM,
  variables,
  shouldFetch = true,
  swrConfig = undefined,
}: GraphProps) {
  const { data } = useSWR(
    shouldFetch ? ['users', chainId, stringify(variables)] : null,
    (_, chainId) => getUserLiquidityPositions(chainId, variables),
    swrConfig
  )
  return data
}

export function useSushiPairs({
  chainId = ChainId.AVALANCHE,
  variables,
  shouldFetch = true,
  swrConfig = undefined,
}: GraphProps) {
  const { data } = useSWR(
    shouldFetch ? ['sushiPairs', chainId, stringify(variables)] : null,
    // @ts-ignore TYPE NEEDS FIXING
    (_, chainId) => getPairs(chainId, variables),
    swrConfig
  )
  //console.log('pairs ' + JSON.stringify(data))
  return data
}

export function useTokens({
  chainId = ChainId.AVALANCHE,
  variables,
  shouldFetch = true,
  swrConfig = undefined,
}: GraphProps) {
  const { data } = useSWR(
    shouldFetch ? ['tokens', chainId, stringify(variables)] : null,
    (_, chainId) => getTokens(chainId, variables),
    swrConfig
  )
  return data
}

export function usePairDayData({
  chainId = ChainId.ETHEREUM,
  variables,
  shouldFetch = true,
  swrConfig = undefined,
}: GraphProps) {
  const { data } = useSWR(
    shouldFetch && !!chainId ? ['pairDayData', chainId, stringify(variables)] : null,
    (_, chainId) => getPairDayData(chainId, variables),
    swrConfig
  )
  return data
}

export function useTokenDayData(
  { chainId, variables, shouldFetch = true }: GraphProps,
  // @ts-ignore TYPE NEEDS FIXING
  swrConfig: SWRConfiguration = undefined
) {
  const { data } = useSWR(
    shouldFetch && !!chainId ? ['tokenDayData', chainId, stringify(variables)] : null,
    (_, chainId) => getTokenDayData(chainId, variables),
    swrConfig
  )
  return data
}

export function useDayData({ chainId, variables, shouldFetch = true, swrConfig = undefined }: GraphProps) {
  const { data } = useSWR(
    shouldFetch && !!chainId ? ['dayData', chainId, stringify(variables)] : null,
    // @ts-ignore TYPE NEEDS FIXING
    (_, chainId) => getDayData(chainId, variables),
    swrConfig
  )
  return data
}

export function useTokenPairs({
  chainId = ChainId.ETHEREUM,
  variables,
  shouldFetch = true,
  swrConfig = undefined,
}: GraphProps) {
  const { data } = useSWR(
    shouldFetch ? ['tokenPairs', chainId, stringify(variables)] : null,
    // @ts-ignore TYPE NEEDS FIXING
    (_, chainId) => getTokenPairs(chainId, variables),
    swrConfig
  )
  return data
}

export function useBurnedTokens({ chainId, variables, shouldFetch = true, swrConfig = undefined }: GraphProps) {
  return useSWR(
    shouldFetch ? ['userTokens', chainId, stringify(variables)] : null,
    (_, chainId) => getBurnedTokens(chainId, variables),
    swrConfig
  )
}
