import { ChainId, SICLE_ADDRESS } from '@swapsicledex/swapsicle-sdk'
import { GRAPH_HOST } from 'app/services/graph/constants'
import {
  burnedTokenQuery,
  dayDatasQuery,
  ethPriceQuery,
  factoryQuery,
  liquidityPositionsQuery,
  userLiquidityPositionsQuery,
  pairDayDatasQuery,
  pairsQuery,
  tokenDayDatasQuery,
  tokenPairsQuery,
  tokenPriceQuery,
  tokenQuery,
  tokensQuery,
  tokenSubsetQuery,
  transactionsQuery,
} from 'app/services/graph/queries'

import { pager } from './pager'

export const EXCHANGE = {
  [ChainId.AVALANCHE]: 'swapsicledex/swapsicle-exchange-avalanche',
  [ChainId.BSC]: 'swapsicledex/swapsicle-exchange-bnb',
  [ChainId.MATIC]: 'swapsicledex/swapsicle-exchange-polygon',
  [ChainId.FANTOM]: 'swapsicledex/swapsicle-exchange-fantom',
  [ChainId.ETHEREUM]: 'swapsicledex/swapsicle-exchange-ethereum',
  [ChainId.OPTIMISM]: 'swapsicledex/swapsicle-exchange-optimism',
  [ChainId.ARBITRUM]: 'swapsicledex/swapsicle-exchange-arbitrum',
  [ChainId.TELOS]: 'swapsicledex/swapsicle-exchange-telos',
  [ChainId.TELOS_TESTNET]: 'swapsicledex/swapsicle-exchange-telos-testnet',

  [ChainId.XDAI]: 'sushiswap/xdai-exchange',
  [ChainId.CELO]: 'jiro-ono/sushitestsubgraph',
  [ChainId.MOONRIVER]: 'sushiswap/moonriver-exchange',
  [ChainId.OKEX]: 'okex-exchange/oec',
  [ChainId.HECO]: 'heco-exchange/heco',
  [ChainId.FUSE]: 'sushiswap/fuse-exchange',
  [ChainId.MOONBEAM]: 'sushiswap/moonbeam-exchange',
}

// @ts-ignore TYPE NEEDS FIXING
export const exchange = async (chainId = ChainId.AVALANCHE, query, variables = {}) => {
  //console.log('graph', `${GRAPH_HOST[chainId]}/subgraphs/name/${EXCHANGE[chainId]}`)

  // @ts-ignore TYPE NEEDS FIXING
  return pager(`${GRAPH_HOST[chainId]}/subgraphs/name/${EXCHANGE[chainId]}`, query, variables)
}

export const getPairs = async (chainId = ChainId.AVALANCHE, variables = undefined, query = pairsQuery) => {
  const { pairs } = await exchange(chainId, query, variables)
  //console.log('Pairs', pairs)
  return pairs
}

// @ts-ignore TYPE NEEDS FIXING
export const getPairDayData = async (chainId = ChainId.AVALANCHE, variables) => {
  // console.log('getTokens')
  const { pairDayDatas } = await exchange(chainId, pairDayDatasQuery, variables)
  //console.log('pairDayDatas', pairDayDatas)
  return pairDayDatas
}

// @ts-ignore TYPE NEEDS FIXING
export const getTokenSubset = async (chainId = ChainId.AVALANCHE, variables) => {
  //console.log('getTokenSubset')
  const { tokens } = await exchange(chainId, tokenSubsetQuery, variables)
  return tokens
}

// @ts-ignore TYPE NEEDS FIXING
export const getTokens = async (chainId = ChainId.AVALANCHE, variables) => {
  //console.log('getTokens')
  const { tokens } = await exchange(chainId, tokensQuery, variables)
  return tokens
}

// @ts-ignore TYPE NEEDS FIXING
export const getToken = async (chainId = ChainId.AVALANCHE, query = tokenQuery, variables) => {
  //console.log('getTokens')
  const { token } = await exchange(chainId, query, variables)
  return token
}

// @ts-ignore TYPE NEEDS FIXING
export const getTokenDayData = async (chainId = ChainId.AVALANCHE, variables) => {
  //console.log('getTokens')
  const { tokenDayDatas } = await exchange(chainId, tokenDayDatasQuery, variables)
  return tokenDayDatas
}

// @ts-ignore TYPE NEEDS FIXING
export const getTokenPrices = async (chainId = ChainId.AVALANCHE, variables) => {
  //console.log('getTokenPrice')
  const { tokens } = await exchange(chainId, tokensQuery, variables)
  // @ts-ignore TYPE NEEDS FIXING
  return tokens.map((token) => token?.derivedETH)
}

// @ts-ignore TYPE NEEDS FIXING
export const getTokenPrice = async (chainId = ChainId.AVALANCHE, query, variables) => {
  //console.log('getTokenPrice')
  const nativePrice = await getNativePrice(chainId)

  const { token } = await exchange(chainId, query, variables)
  return token?.derivedETH * nativePrice
}

export const getNativePrice = async (chainId = ChainId.AVALANCHE, variables = undefined) => {
  //console.log('getEthPrice')
  const data = await getBundle(chainId, undefined, variables)
  return data?.bundles[0]?.ethPrice
}

export const getEthPrice = async (variables = undefined) => {
  return getNativePrice(ChainId.AVALANCHE, variables)
}

export const getGlimmerPrice = async (variables = {}) => {
  return getTokenPrice(ChainId.MOONBEAM, tokenPriceQuery, {
    id: '0xacc15dc74880c9944775448304b263d191c6077f',
    ...variables,
  })
}

export const getYggPrice = async (variables = {}) => {
  return getTokenPrice(ChainId.AVALANCHE, tokenPriceQuery, {
    id: '0x25f8087ead173b73d6e8b84329989a8eea16cf73',
    ...variables,
  })
}

export const getRulerPrice = async (variables = {}) => {
  return getTokenPrice(ChainId.AVALANCHE, tokenPriceQuery, {
    id: '0x2aeccb42482cc64e087b6d2e5da39f5a7a7001f8',
    ...variables,
  })
}

export const getTruPrice = async (variables = {}) => {
  return getTokenPrice(ChainId.AVALANCHE, tokenPriceQuery, {
    id: '0x4c19596f5aaff459fa38b0f7ed92f11ae6543784',
    ...variables,
  })
}

export const getCvxPrice = async (variables = {}) => {
  return getTokenPrice(ChainId.AVALANCHE, tokenPriceQuery, {
    id: '0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b',
    ...variables,
  })
}

export const getMaticPrice = async (variables = {}) => {
  // console.log('getMaticPrice')
  return getTokenPrice(ChainId.MATIC, tokenPriceQuery, {
    id: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    ...variables,
  })
}

export const getAlcxPrice = async (variables = {}) => {
  // console.log('getAlcxPrice')
  return getTokenPrice(ChainId.AVALANCHE, tokenPriceQuery, {
    id: '0xdbdb4d16eda451d0503b854cf79d55697f90c8df',
    ...variables,
  })
}

export const getPicklePrice = async (variables = {}) => {
  return getTokenPrice(ChainId.AVALANCHE, tokenPriceQuery, {
    id: '0x429881672b9ae42b8eba0e26cd9c73711b891ca5',
    ...variables,
  })
}

export const getMphPrice = async (variables = {}) => {
  return getTokenPrice(ChainId.AVALANCHE, tokenPriceQuery, {
    id: '0x8888801af4d980682e47f1a9036e589479e835c5',
    ...variables,
  })
}

export const getSushiPrice = async (variables = {}) => {
  // console.log('getSushiPrice')
  return getTokenPrice(ChainId.AVALANCHE, tokenPriceQuery, {
    id: SICLE_ADDRESS[ChainId.AVALANCHE].toLowerCase(),
    ...variables,
  })
}

export const getAvaxPrice = async (variables = undefined) => {
  return getNativePrice(ChainId.AVALANCHE, variables)
}

export const getFantomPrice = async () => {
  return getTokenPrice(ChainId.FANTOM, tokenPriceQuery, {
    id: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
  })
}

export const getSTLOSPrice = async () => {
  return getTokenPrice(ChainId.TELOS, tokenPriceQuery, {
    id: '0xb4b01216a5bc8f1c8a33cd990a1239030e60c905',
  })
}

export const getFORTPrice = async () => {
  return getTokenPrice(ChainId.TELOS, tokenPriceQuery, {
    id: '0xBA7DC28e0E33685ae4Ee2430BC4e418DbEa532FF'.toLowerCase(),
  })
}

export const getBundle = async (
  chainId = ChainId.AVALANCHE,
  query = ethPriceQuery,
  variables = {
    id: 1,
  }
) => {
  return exchange(chainId, query, variables)
}

// @ts-ignore TYPE NEEDS FIXING
export const getLiquidityPositions = async (chainId = ChainId.AVALANCHE, variables) => {
  const { liquidityPositions } = await exchange(chainId, liquidityPositionsQuery, variables)
  return liquidityPositions
}
export const getUserLiquidityPositions = async (chainId = ChainId.AVALANCHE, variables) => {
  const { users } = await exchange(chainId, userLiquidityPositionsQuery, variables)
  return users
}

export const getDayData = async (chainId = ChainId.AVALANCHE, variables = undefined) => {
  const { dayDatas } = await exchange(chainId, dayDatasQuery, variables)
  return dayDatas
}

export const getFactory = async (chainId = ChainId.AVALANCHE, variables = undefined) => {
  const { factories } = await exchange(chainId, factoryQuery, variables)
  return factories[0]
}

export const getTransactions = async (chainId = ChainId.AVALANCHE, variables = undefined) => {
  const { swaps } = await exchange(chainId, transactionsQuery, variables)
  return swaps
}

export const getTokenPairs = async (chainId = ChainId.AVALANCHE, variables = undefined) => {
  const { pairs0, pairs1 } = await exchange(chainId, tokenPairsQuery, variables)
  return pairs0 || pairs1 ? [...(pairs0 ? pairs0 : []), ...(pairs1 ? pairs1 : [])] : undefined
}

export const getBurnedTokens = async (chainId = ChainId.AVALANCHE, variables = undefined) => {
  const { userTokens } = await exchange(chainId, burnedTokenQuery, variables)
  return userTokens
}
