import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import Web3Connect from '../Web3Connect'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { useActiveWeb3React } from '../../services/web3'
import useIsCoinbaseWallet from '../../hooks/useIsCoinbaseWallet'
import { useETHBalances } from '../../state/wallet/hooks'
import Web3Status from 'app/components/Web3Status'
import Web3Network from 'app/components/Web3Network'
import { useRouter } from 'next/router'
import { ChainId, NATIVE } from '@swapsicledex/swapsicle-sdk'
// import CoinbaseButton from '../CoinbasePay'

export default function Header() {
  const [navbar, setNavbar] = useState(false)
  const { account, chainId, library } = useActiveWeb3React()
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const isCoinbaseWallet = useIsCoinbaseWallet()
  const router = useRouter()

  return (
    <div className="as_header">
      <div className="justify-between px-4 md:items-center lg:flex md:px-8 header_cont">
        <div>
          <div className="flex items-center justify-between lg:block">
            <div className="a_logo">
              <Link href="/" passHref={true}>
                <Image
                  src="/images/logo-light.svg"
                  alt="Swapsicle logo"
                  layout="fill"
                  objectFit="contain"
                  objectPosition="left"
                />
              </Link>
            </div>
            <div className="lg:hidden">
              <button
                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`mblnvbr flex-1 justify-self-center pb-3 mt-8 lg:block md:pb-0 lg:mt-0 ${navbar ? 'block' : 'hidden'
              }`}
          >
            <div className="right_header">
              <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0 as_menu_itms">
                <li
                  className="text-gray-600 hover:text-blue-600 clr_mn_swp"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    router.push('/swap')
                  }}
                >
                  {/* <Link href="/swap" passHref={true}> */}
                  <a>Swap</a>
                  {/* </Link> */}
                </li>

                {/* Launchpad */}

                <li
                  className="text-gray-600 hover:text-blue-600 clr_mn_swp"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    router.push('/launchpad')
                  }}
                >
                  <a>Launchpad</a>
                </li>

                {/* Pro */}

                <li className="text-gray-600 hover:text-blue-600 clr_mn_pool">
                  <Menu as="div" className="relative inline-block text-left lnd_plsmn">
                    <Menu.Button>
                      Pro
                      <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none lnddrpdwn">
                        <div className="py-3">
                          <Menu.Item>
                            <Link href="https://pro.swapsicle.io" passHref={true}>
                              Trade
                            </Link>
                          </Menu.Item>
                          <Menu.Item>
                            <Link href="/leaderboard" passHref={true}>
                              Pro Rewards
                            </Link>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </li>

                {/* Pool */}

                {/* <li className="text-gray-600 hover:text-blue-600 clr_mn_pool">
                  <Menu as="div" className="relative inline-block text-left lnd_plsmn">
                    <Menu.Button>
                      Pool
                      <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none lnddrpdwn">
                        <div className="py-3">
                          <Menu.Item>
                            <Link href="/browse" passHref={true}>
                              Browse
                            </Link>
                          </Menu.Item>
                          <Menu.Item>
                            <Link href="/pool" passHref={true}>
                              My Pools
                            </Link>
                          </Menu.Item>
                          <Menu.Item>
                            <Link href="/add/ETH/0x240248628B7B6850352764C5dFa50D1592A033A8" passHref={true}>
                              Create
                            </Link>
                          </Menu.Item>
                          <Menu.Item>
                            <Link href="/find" passHref={true}>
                              Import
                            </Link>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </li> */}

                {chainId == ChainId.AVALANCHE || chainId == ChainId.TELOS || chainId == ChainId.TELOS_TESTNET ?
                  <li className="text-gray-600 hover:text-blue-600 clr_mn_farm">
                    <Menu as="div" className="relative inline-block text-left lnd_farms">
                      <Menu.Button>
                        Farm
                        <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute left-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none lnddrpdwn">
                          <div className="py-3">
                            <Menu.Item>
                              <Link href="/farm?filter=portfolio" passHref={true}>
                                Your Farm
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link href="/farm" passHref={true}>
                                All Farm
                              </Link>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </li>
                  : <></>}

                {
                  // chainId == ChainId.AVALANCHE ?
                  <li className="text-gray-600 hover:text-blue-600 clr_mn_iccrm">
                    <Link href="/ice-cream-van" passHref={true}>
                      Stake
                    </Link>
                  </li>
                  // : <></>
                }
                {chainId != ChainId.AVALANCHE && chainId != ChainId.TELOS && chainId != ChainId.TELOS_TESTNET ?
                  <li className="text-gray-600 hover:text-blue-600 clr_mn_rwrd">
                    <Link href="/rewards" passHref={true}>
                      Rewards
                    </Link>
                  </li>
                  : <></>}

              </ul>

              <div className="flex rspnv_bx">
                <div className="flex flex-col gap-4 px-6">
                  <div
                    style={{
                      background:
                        'linear-gradient(#131928 0 0) padding-box,linear-gradient(135deg, #5AF9C4 0%, #3D5DFF 50%, #FF73E1 100%) border-box',
                      border: '2px solid transparent',
                      borderRadius: '10px',
                    }}
                    className="flex items-center justify-start gap-2"
                  >
                    <div className="flex items-center w-auto text-sm font-bold border-2 rounded shadow cursor-pointer pointer-events-auto select-none border-dark-800 hover:border-dark-700 bg-dark-900 whitespace-nowrap">
                      <Web3Network />
                      {account && chainId && userEthBalance && (
                        <Link href={`/swap`} passHref={true}>
                          <a className="hidden px-3 text-high-emphesis text-bold md:block">
                            {userEthBalance?.toSignificant(4)} {NATIVE[chainId || 1].symbol}
                          </a>
                        </Link>
                      )}
                      <Web3Status />
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
