import { NETWORK_ICON } from 'app/config/networks'
import NetworkModel, { SUPPORTED_NETWORKS } from 'app/modals/NetworkModal'
import { useActiveWeb3React } from 'app/services/web3'
import { useNetworkModalToggle } from 'app/state/application/hooks'
import Image from 'next/image'
import React from 'react'
import Select from 'react-select'
import { ChainId } from '@swapsicledex/swapsicle-sdk'

const options = [
  {
    label: (
      <div className="crnc_options">
        <Image src="/img/telos.svg" alt="" height={28} width={28} /> <p>Telos</p>
      </div>
    ),
    value: 40,
  },
  {
    label: (
      <div className="crnc_options">
        <Image src="/img/avx_ic.svg" alt="" height={28} width={28} /> <p>Avalanche</p>
      </div>
    ),
    value: 43114,
  },
  {
    label: (
      <div className="crnc_options">
        <Image src="/img/matic.svg" alt="" height={28} width={28} /> <p>Polygon</p>
      </div>
    ),
    value: 137,
  },
  {
    label: (
      <div className="crnc_options">
        <Image src="/img/bsc_ic.svg" alt="" height={28} width={28} /> <p>BNB</p>
      </div>
    ),
    value: 56,
  },
  {
    label: (
      <div className="crnc_options">
        <Image src="/img/fantom.svg" alt="" height={28} width={28} /> <p>Fantom</p>
      </div>
    ),
    value: 250,
  },
  {
    label: (
      <div className="crnc_options">
        <Image src="/img/ethereum.svg" alt="" height={28} width={28} /> <p>Ethereum</p>
      </div>
    ),
    value: 1,
  },
  {
    label: (
      <div className="crnc_options">
        <Image src="/img/arbitrum.svg" alt="" height={28} width={28} /> <p>Arbitrum</p>
      </div>
    ),
    value: 42161
  },
  {
    label: (
      <div className="crnc_options">
        <Image src="/img/optimism.svg" alt="" height={28} width={28} /> <p>Optimism</p>
      </div>
    ),
    value: 10,
  },
  {
    label: (
      <div className="crnc_options">
        <Image src="/img/telos.svg" alt="" height={28} width={28} /> <p>Telos Testnet</p>
      </div>
    ),
    value: 41,
  },

]

function Web3Network(): JSX.Element | null {
  const { chainId, library, account } = useActiveWeb3React()

  const toggleNetworkModal = useNetworkModalToggle()

  if (!chainId) return null

  return (
    <div
      className="flex items-center text-sm font-bold border-2 rounded cursor-pointer pointer-events-auto select-none border-dark-800 hover:border-dark-700 bg-dark-1000 hover:bg-dark-900 whitespace-nowrap"
    // onClick={() => toggleNetworkModal()}
    >
      <div className="grid items-center justify-center grid-flow-col text-sm rounded pointer-events-auto bg-dark-1000 auto-cols-max text-secondary crncy_select_prnt">
        {/*@ts-ignore TYPE NEEDS FIXING*/}
        {/* <Image src={NETWORK_ICON[chainId]} alt="Switch Network" className="rounded-md" width="22px" height="22px" /> */}
        <Select
          isSearchable={false}
          onChange={async (e) => {
            let key = e.value
            console.debug(`Switching to chain ${key}`, SUPPORTED_NETWORKS[key])
            const params = SUPPORTED_NETWORKS[key]
            try {
              await library?.send('wallet_switchEthereumChain', [{ chainId: `0x${key.toString(16)}` }, account])
            } catch (switchError) {
              // This error code indicates that the chain has not been added to MetaMask.
              // @ts-ignore TYPE NEEDS FIXING
              // if (switchError.code === 4902) {
              try {
                await library?.send('wallet_addEthereumChain', [params, account])
              } catch (addError) {
                // handle "add" error
                console.error(`Add chain error ${addError}`)
              }
              // }
              console.error(`Switch chain error ${switchError}`)
              // handle other "switch" errors
            }
          }}
          options={options}
          className="crncy_select"
          placeholder="Client"
          classNamePrefix="crncy_select_inn"
          value={options.filter(e => e.value == chainId)[0]}
        />
      </div>
      <NetworkModel />
    </div>
  )
}

export default Web3Network
