import { ChainId, JSBI, Percent } from '@swapsicledex/swapsicle-sdk'

// TODO: Move some of this to config level...

// TODO: update weekly with new constant
export const WEEKLY_MERKLE_ROOT =
  //'https://raw.githubusercontent.com/sushiswap/sushi-vesting/master/merkle/week-13/merkle-10959148-11550728.json'
  //'https://raw.githubusercontent.com/sushiswap/sushi-vesting/master/merkle/week-14/merkle-10959148-11596364.json'
  //'https://raw.githubusercontent.com/sushiswap/sushi-vesting/master/merkle/week-15/merkle-10959148-11641996.json'
  //'https://raw.githubusercontent.com/sushiswap/sushi-vesting/master/merkle/week-16/merkle-10959148-11687577.json'
  //'https://raw.githubusercontent.com/sushiswap/sushi-vesting/master/merkle/week-17/merkle-10959148-11733182.json'
  //'https://raw.githubusercontent.com/sushiswap/sushi-vesting/master/merkle/week-18/merkle-10959148-11778625.json'
  //'https://raw.githubusercontent.com/sushiswap/sushi-vesting/master/merkle/week-19/merkle-10959148-11824101.json'
  //'https://raw.githubusercontent.com/sushiswap/sushi-vesting/master/merkle/week-20/merkle-10959148-11869658.json'
  //'https://raw.githubusercontent.com/sushiswap/sushi-vesting/master/merkle/week-21/merkle-10959148-11915191.json'
  //'https://raw.githubusercontent.com/sushiswap/sushi-vesting/master/merkle/week-22/merkle-10959148-11960663.json'
  //'https://raw.githubusercontent.com/sushiswap/sushi-vesting/master/merkle/week-23/merkle-10959148-12006121.json'
  //'https://raw.githubusercontent.com/sushiswap/sushi-vesting/master/merkle/week-24/merkle-10959148-12051484.json'
  //'https://raw.githubusercontent.com/sushiswap/sushi-vesting/master/merkle/week-24/protocol-claim.json'
  //'https://raw.githubusercontent.com/sushiswap/sushi-vesting/master/merkle/week-24/merkle-10959148-12051484-2.json'
  //'https://raw.githubusercontent.com/sushiswap/sushi-vesting/master/merkle/week-25/merkle-10959148-12096934.json'
  //'https://raw.githubusercontent.com/sushiswap/sushi-vesting/master/merkle/week-26/merkle-10959148-12142433.json'
  'https://raw.githubusercontent.com/sushiswap/sushi-vesting/master/merkle/week-27/merkle-10959148-12171394.json'

export const PROTOCOL_MERKLE_ROOT =
  //'https://raw.githubusercontent.com/sushiswap/sushi-vesting/master/merkle/protocol/merkle-10959148-12171394.json'
  'https://raw.githubusercontent.com/sushiswap/sushi-vesting/master/merkle/protocol-02/merkle-10959148-12171394.json'

export const NetworkContextName = 'NETWORK'

// 30 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 30

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)

// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%

// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%

// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH

export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')

export const ONE_HUNDRED_PERCENT = new Percent('1')

export const ANALYTICS_URL: { [chainId in ChainId]?: string } = {
  [ChainId.ETHEREUM]: 'https://analytics.sushi.com',
  [ChainId.MATIC]: 'https://analytics-polygon.sushi.com',
  [ChainId.FANTOM]: 'https://analytics-ftm.sushi.com',
  [ChainId.BSC]: 'https://analytics-bsc.sushi.com',
  [ChainId.XDAI]: 'https://analytics-xdai.sushi.com',
  [ChainId.HARMONY]: 'https://analytics-harmony.sushi.com',
  [ChainId.ARBITRUM]: 'https://analytics-arbitrum.sushi.com',
  [ChainId.FUSE]: 'https://analytics-fuse.sushi.com',
  [ChainId.MOONRIVER]: 'https://analytics-moonriver.sushi.com',
  [ChainId.CELO]: 'https://analytics-celo.sushi.com',
}

export const EIP_1559_ACTIVATION_BLOCK: { [chainId in ChainId]?: number } = {
  [ChainId.ETHEREUM]: 12965000,
  [ChainId.ROPSTEN]: 10499401,
  [ChainId.GÖRLI]: 5062605,
  [ChainId.RINKEBY]: 8897988,
}

// dev
export const COINBASE_APPID = '54311e4f-a063-42c9-9101-ebaf579776c9'
export const ONRAMPER_APPID = 'pk_test_dz5MKqOMrfSd0_HlDqDWgJFyVXVDEYt7EWDADCEHW8Q0'

export const CLAIM_GRAPE_FINANCE = '0x25fce82cebacbb46b7e18380edd2d2a418a36792'
export const CLAIM_ONAVAX = '0x7c98493bad3c39217546c69037114dfdcc529e6c'
export const SLP_VINEYARD_STAKE_ADDRESS = '0x28c65dcb3a5f0d456624aff91ca03e4e315bee49'
export const GRAPE_NODES_ADDRESS = '0xfCbD88AD9a9f33a227c307EC1478bCDeB0412EdB'
export const CLAIM_GRAPE_NODES_ADDRESS = '0x01c8Fe54317C1F6d9E6dBCbB1A1f6C1df9bc5dcF'
export const WALRUS_STAKE_ADDRESS = '0x752FEacFdA5c3B440Fd6D40ECf338a86b568c2d2'
export const WALRUS_REWARDS_ADDRESS = '0x4fbcDf38Ff39177b70fe2dF82Aa09d1908Ad41D6'
export const TRADEATHON_REWARDS_ADDRESS = '0xEE951A07745cB1702bC7B5305721041d2191696F' //arbitrum
export const CLAIM_DAY_WALRUS = 3
export const VINEYARD_STAKE_POOL_ID = 5
export const CLAIM_DATE_ONAVAX = 25
export const CLAIM_DAY_GRAPE = 0
export const CLAIM_DAY_PRO = 3
export const PARASWAP_FEE = 0.15 / 100
export const SWAPSICLE_BUFFER = 0.25 / 100
export const PARTNER_FEE = 0.15 * 100 //10 = 0.1%, 0.13 * 100 = 0.15%
export const TOKEN_EXCLUDED_FEE_DEST = [
  '0x59b18817ca9f4ad2dee6fbf30132df6aeb9d763d', //AVIC
  //'0x74fefa839a96a1632a29e0fcf0907d0f88528658', //EVIC
  '0x92876c3a3e2b0788c841587a14989392a555bffe', //MIND+
  //'0xad92430a77854d72fcbbc5f4ec1e9f89d7585b90', //UVIC
]
export const TOKEN_EXCLUDED_FEE_SRC = [
  '0x74fefa839a96a1632a29e0fcf0907d0f88528658', //EVIC
  '0xad92430a77854d72fcbbc5f4ec1e9f89d7585b90', //UVIC
]

export const ICE_CREAM_API = process.env.NEXT_PUBLIC_SWAPSICLE_ICECREAMTIME

export const LAUNCHPAD_EXTRA = [
  {
    nameOverride: 'Clout Finance',
    launchpadAddress: '0xeEc2139f45C6a61348b4bC3406F540EC8cb63210',
    launchPrice: '0.6',
    launchPriceToken: 'USDC',
    kyc: true,
  },
  {
    nameOverride: '8Bit Arcade',
    launchpadAddress: '0x9Ea2D0db51bDf7da43c418b12d03420368B55346',
    launchPrice: '1.0',
    launchPriceToken: 'USDT',
    kyc: true,
  },
  {
    nameOverride: 'Fortis Coin',
    launchpadAddress: '0xC674e696b99819b8E92e69982BdD6E7495937C43',
    launchPrice: '0.0035',
    launchPriceToken: 'USDC',
    tokenPriceUSDC: '0.0030',
    minRaiseUSDC: 2000,
    maxRaiseUSDC: 20000,
    kyc: true,
  },
]
