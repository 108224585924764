import { Dialog, Transition } from '@headlessui/react'
import { MenuIcon } from '@heroicons/react/outline'
import { ChainId, NATIVE } from '@swapsicledex/swapsicle-sdk'
import useMenu from 'app/components/Header/useMenu'
import Web3Network from 'app/components/Web3Network'
import Web3Status from 'app/components/Web3Status'
import NetworkModel, { SUPPORTED_NETWORKS } from 'app/modals/NetworkModal'
import useIsCoinbaseWallet from 'app/hooks/useIsCoinbaseWallet'
import { useActiveWeb3React } from 'app/services/web3'
import { useETHBalances } from 'app/state/wallet/hooks'
import Image from 'next/image'
import Link from 'next/link'
import React, { FC, Fragment, useState, useEffect, useRef } from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { useRouter } from 'next/router'
import Select from 'react-select'

const options = [
  {
    label: (
      <div className="crnc_options">
        <Image src="/img/telos.svg" alt="" height={28} width={28} /> <p>Telos</p>
      </div>
    ),
    value: 40,
  },
  {
    label: (
      <div className="crnc_options">
        <Image src="/img/avx_ic.svg" alt="" height={28} width={28} /> <p>Avalanche</p>
      </div>
    ),
    value: 43114,
  },
  {
    label: (
      <div className="crnc_options">
        <Image src="/img/matic.svg" alt="" height={28} width={28} /> <p>Polygon</p>
      </div>
    ),
    value: 137,
  },
  {
    label: (
      <div className="crnc_options">
        <Image src="/img/bsc_ic.svg" alt="" height={28} width={28} /> <p>BNB</p>
      </div>
    ),
    value: 56,
  },
  {
    label: (
      <div className="crnc_options">
        <Image src="/img/fantom.svg" alt="" height={28} width={28} /> <p>Fantom</p>
      </div>
    ),
    value: 250,
  },
  {
    label: (
      <div className="crnc_options">
        <Image src="/img/ethereum.svg" alt="" height={28} width={28} /> <p>Ethereum</p>
      </div>
    ),
    value: 1,
  },
  {
    label: (
      <div className="crnc_options">
        <Image src="/img/arbitrum.svg" alt="" height={28} width={28} /> <p>Arbitrum</p>
      </div>
    ),
    value: 42161,
  },
  {
    label: (
      <div className="crnc_options">
        <Image src="/img/optimism.svg" alt="" height={28} width={28} /> <p>Optimism</p>
      </div>
    ),
    value: 10,
  },

  {
    label: (
      <div className="crnc_options">
        <Image src="/img/telos.svg" alt="" height={28} width={28} /> <p>Telos Testnet</p>
      </div>
    ),
    value: 41,
  }
]

const Mobile: FC = () => {
  const { account, chainId, library } = useActiveWeb3React()
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const [open, setOpen] = useState(false)
  const isCoinbaseWallet = useIsCoinbaseWallet()
  const router = useRouter()

  let menu = ''
  if (
    router.asPath.startsWith('/pool') ||
    router.asPath.startsWith('/add/ETH') ||
    router.asPath.startsWith('/find') ||
    router.asPath.startsWith('/remove')
  ) {
    menu = 'pool'
  } else if (router.asPath.startsWith('/farm')) {
    menu = 'farm'
  } else if (router.asPath.startsWith('/ice-box')) {
    menu = 'ice-box'
  } else if (router.asPath.startsWith('/ice-cream-van')) {
    menu = 'ice-cream-van'
  } else if (router.asPath.startsWith('/ice-vault')) {
    menu = 'ice-vault'
  } else if (router.asPath.startsWith('/cross-chain')) {
    menu = 'cross-chain'
  } else if (router.asPath.startsWith('/leaderboard')) {
    menu = 'pro'
  } else if (router.asPath.startsWith('/swap')) {
    menu = 'swap'
  }

  const perpURL = "https://pro.swapsicle.io"

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          //    if ( menu === true) {
          setOpen(false);
          //        }
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef)


  return (
    <>
      <header className="w-full flex items-center justify-between min-h-[64px] h-[64px] px-4" >
        <div className="flex justify-between flex-grow" /* ref={wrapperRef} */>
          <div className="p-2 rounded-full hover:bg-white/10">
            <MenuIcon width={28} className="text-white cursor-pointer hover:text-white" onClick={() => setOpen(true)} />
          </div>
          <div className="flex items-center mr-1" style={{ width: '10rem', marginTop: '5px' }}>
            {/* <Box width={120} height={40}> */}
            {/* <Link href="/swap" passHref={true}>
                <Image src="/images/logo-light.png" alt="Swapsicle logo" width="120px" height="40px" layout='responsive'/>
              </Link> */}
            {/* </Box> */}

            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
              <Link href="/" passHref={true}>
                <Image src="/images/logo-light.svg" alt="Swapsicle logo" layout="fill" objectFit="contain" />
              </Link>
            </div>
          </div>
        </div>
        <Transition.Root show={open} as={Fragment} unmount={false}>
          <Dialog as="div" className="fixed inset-0 z-50 overflow-hidden" onClose={setOpen} unmount={false}>
            <div className="absolute inset-0 overflow-hidden">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="absolute inset-0 transition-opacity bg-dark-1000 bg-opacity-80" />
              </Transition.Child>

              <div className="fixed inset-y-0 left-0 pr-10 max-w-[260px] flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-300"
                  enterFrom="translate-x-[-100%]"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-[-100%]"
                  unmount={false}
                >
                  <div className="w-screen max-w-sm">
                    <div className="flex flex-col h-full py-6 overflow-x-hidden overflow-y-scroll shadow-xl bg-dark-800">

                      <nav className="flex-1 pl-6" aria-label="Sidebar" ref={wrapperRef}>
                        {/* {menu.map((node) => {
                          return <NavigationItem node={node} key={node.key} />
                        })} */}

                        <div className="w-24 p-2 rounded-full hover:bg-white/10">
                          <MenuIcon width={28} className="text-white cursor-pointer hover:text-white" onClick={() => setOpen(false)} />
                        </div>

                        <ul className="main_sd_mn">


                          {/* Swap */}

                          {/* {chainId == ChainId.AVALANCHE || chainId == ChainId.MATIC || chainId == ChainId.FANTOM ? ( */}
                          <li>
                            <Disclosure defaultOpen={menu == 'swap' ? true : false}>
                              <Disclosure.Button className="drpdwn_mn_btn" >
                                <a className={menu == 'swap' ? 'swap_ic active' : 'swap_ic'}>
                                  <div className="mn_ic">
                                    <Image src="/icons/swap_ic.svg" alt="Swap" height={'16px'} width={'17px'} />
                                  </div>
                                  Swap
                                </a>

                                <ChevronDownIcon className="mn_hndlr" />
                              </Disclosure.Button>
                              <Disclosure.Panel className="mainlst" >
                                <div className="inner_mn_list inner_mn_list_swap">
                                  <Link href="/swap">
                                    <a className={router.asPath == '/swap' ? 'active' : ''} onClick={() => setOpen(false)}>Crypto</a>
                                  </Link>
                                  <Link href="/swap/fiat">
                                    <a className={router.asPath == '/swap/fiat' ? 'active' : ''} onClick={() => setOpen(false)}>Fiat</a>
                                  </Link>
                                </div>
                              </Disclosure.Panel>
                            </Disclosure>
                          </li>

                          {/* Perpetuals / Pro */}

                          {/* <li>
                        <Link href={perpURL}>
                          <a className='pro_ic'>
                            <div className="mn_ic">
                              <Image src="/icons/Pro_ic2.svg" alt="Perpetuals" height={'16px'} width={'17px'} />
                            </div>
                            <p>Swapsicle Pro</p>
                          </a>
                        </Link>
                      </li> */}

                          <li>
                            <Disclosure defaultOpen={menu == 'pro' ? true : false}>
                              <Disclosure.Button className="drpdwn_mn_btn">
                                <a className={menu == 'pro' ? 'pro_ic active' : 'pro_ic'}>
                                  <div className="mn_ic">
                                    <Image src="/icons/Pro_ic2.svg" alt="Perpetuals" height={'16px'} width={'17px'} />
                                  </div>
                                  <p>Swapsicle Pro</p>
                                </a>
                                <ChevronDownIcon className="mn_hndlr" />
                              </Disclosure.Button>

                              <Disclosure.Panel className="mainlst">
                                <div className="inner_mn_list inner_mn_list_pro">
                                  <Link href={perpURL}>
                                    <a className={router.asPath == '/pro' ? 'active' : ''}><p>Trade</p></a>
                                  </Link>
                                  <Link href="/leaderboard">
                                    <a className={router.asPath == '/leaderboard' ? 'active' : ''}>Pro Rewards</a>
                                  </Link>
                                </div>
                              </Disclosure.Panel>
                            </Disclosure>
                          </li>

                          {/* Cross-Chain  */}

                          {/* <li>
                            <Disclosure defaultOpen={menu == 'cross-chain' ? true : false}>
                              <Disclosure.Button className="drpdwn_mn_btn">
                                <a className={menu == 'cross-chain' ? 'crosschain_ic active' : 'crosschain_ic'}>
                                  <div className="mn_ic">
                                    <Image src="/icons/bridge-icon-red.svg" alt="Cross-Chain" height={'21px'} width={'21px'} />
                                  </div>
                                  Cross-Chain
                                </a>

                                <ChevronDownIcon className="mn_hndlr" />
                              </Disclosure.Button>
                              <Disclosure.Panel className="mainlst">
                                <div className="inner_mn_list inner_mn_list_bridge">
                                  <Link href="https://app.multichain.org/#/router">
                                    <a className={router.asPath == '/cross-chain/bridge' ? 'active' : ''}>Bridge</a>
                                  </Link>
                                </div>
                              </Disclosure.Panel>
                            </Disclosure>
                          </li> */}

                          {/* Pool  */}

                          <li>
                            <Disclosure defaultOpen={menu == 'pool' ? true : false}>
                              <Disclosure.Button className="drpdwn_mn_btn">
                                <a className={menu == 'pool' ? 'pll_ic active' : 'pll_ic'}>
                                  <div className="mn_ic">
                                    <Image src="/icons/pool_m_ic.svg" alt="Pool" height={'21px'} width={'21px'} />
                                  </div>
                                  Pools
                                </a>

                                <ChevronDownIcon className="mn_hndlr" />
                              </Disclosure.Button>
                              <Disclosure.Panel className="mainlst">
                                <div className="inner_mn_list">
                                  <Link href="/browse">
                                    <a className={router.asPath == '/browse' ? 'active' : ''} onClick={() => setOpen(false)}>Browse</a>
                                  </Link>
                                  <Link href="/pool">
                                    <a className={router.asPath == '/pool' ? 'active' : ''} onClick={() => setOpen(false)}>My Pools</a>
                                  </Link>
                                  <Link href="/add/ETH/0x240248628B7B6850352764C5dFa50D1592A033A8">
                                    <a className={router.asPath.startsWith('/add/ETH/') ? 'active' : ''} onClick={() => setOpen(false)}>Create</a>
                                  </Link>
                                  <Link href="/find">
                                    <a className={router.asPath == '/find' ? 'active' : ''} onClick={() => setOpen(false)}>Import</a>
                                  </Link>
                                </div>
                              </Disclosure.Panel>
                            </Disclosure>
                          </li>
                          {/* ) : (
                            <></>
                          )} */}



                          {/* Farms  */}

                          {chainId == ChainId.AVALANCHE || chainId == ChainId.TELOS || chainId == ChainId.TELOS_TESTNET ? (
                            <li>
                              <Disclosure defaultOpen={menu == 'farm' ? true : false}>
                                <Disclosure.Button className="drpdwn_mn_btn">
                                  <a className={menu == 'farm' ? 'farm_ic active' : 'farm_ic'}>
                                    <div className="mn_ic">
                                      <Image src="/icons/farn_ic.svg" alt="Farm" height={'20px'} width={'20px'} />
                                    </div>
                                    Farm
                                  </a>

                                  <ChevronDownIcon className="mn_hndlr" />
                                </Disclosure.Button>
                                <Disclosure.Panel className="mainlst">
                                  <div className="inner_mn_list">
                                    <Link href="/farm">
                                      <a className={router.asPath == '/farm' ? 'active' : ''} onClick={() => setOpen(false)}>All Farms</a>
                                    </Link>
                                    <Link href="/farm?filter=portfolio">
                                      <a className={router.asPath.startsWith('/farm?filter=portfolio') ? 'active' : ''} onClick={() => setOpen(false)}>
                                        My Farms
                                      </a>
                                    </Link>
                                  </div>
                                </Disclosure.Panel>
                              </Disclosure>
                            </li>
                          ) : (
                            <></>
                          )}

                          {/* Stake */}

                          {/* {chainId == ChainId.AVALANCHE ? ( */}
                          <li>
                            <Disclosure
                              defaultOpen={
                                menu == 'ice-cream-van' || menu == 'ice-box' || menu == 'ice-vault' ? true : false
                              }
                            >
                              <Disclosure.Button className="drpdwn_mn_btn">
                                <a
                                  className={
                                    menu == 'ice-box'
                                      ? 'ice_box active'
                                      : menu === 'ice-cream-van'
                                        ? 'ice_van active'
                                        : menu === 'ice-vault'
                                          ? 'ice_vault active'
                                          : 'stake_ic'
                                  }
                                >
                                  <div className="mn_ic">
                                    {menu == 'ice-box' ? (
                                      <Image
                                        src="/icons/stake_box.svg"
                                        alt="IceBox"
                                        height={'20px'}
                                        width={'20px'}
                                      />
                                    ) : menu === 'ice-cream-van' ? (
                                      <Image src="/icons/stake_van.svg" alt="Van" height={'20px'} width={'20px'} />
                                    ) : menu === 'ice-vault' ? (
                                      <Image
                                        src="/icons/stake_vault.svg"
                                        alt="Vault"
                                        height={'20px'}
                                        width={'20px'}
                                      />
                                    ) : (
                                      <Image src="/icons/stake.svg" alt="Farm" height={'20px'} width={'20px'} />
                                    )}
                                  </div>
                                  Stake
                                </a>

                                <ChevronDownIcon className="mn_hndlr" />
                              </Disclosure.Button>
                              <Disclosure.Panel className="mainlst">
                                <div className="inner_mn_list inner_mn_list_stake">

                                  <Link href="/ice-cream-van">
                                    <a className={menu === 'ice-cream-van' ? 'ice_van active' : 'ice_van'} onClick={() => setOpen(false)}>
                                      Ice Cream Van
                                    </a>
                                  </Link>

                                  <Link href="/ice-box">
                                    <a className={menu === 'ice-box' ? 'ice_box active' : 'ice_box'}>IceBox</a>
                                  </Link>
                                  <Link href="/ice-vault">
                                    <a className={menu === 'ice-vault' ? 'ice_vault active' : 'ice_vault'}>
                                      IceVault
                                    </a>
                                  </Link>
                                </div>
                              </Disclosure.Panel>
                            </Disclosure>
                          </li>


                          {/* <li>
                            <Link href="/ice-cream-time">
                              <a
                                className={
                                  router.pathname == '/ice-cream-time' ? 'icecrmtime_ic active' : 'icecrmtime_ic'
                                } onClick={() => setOpen(false)}
                              >
                                <div className="mn_ic">
                                  <Image
                                    src="/icons/ice_cream_time_ic.svg"
                                    alt="icecreamtime"
                                    height={'20px'}
                                    width={'19px'}
                                  />
                                </div>
                                Ice Cream Time
                              </a>
                            </Link>
                          </li> */}



                          {/* Ice Cream Time  */}

                          <li>
                            <Link href="/launchpad">
                              <a
                                className={
                                  router.pathname == '/launchpad' ? 'lp_ic active' : 'lp_ic'
                                } onClick={() => setOpen(false)}
                              >
                                <div className="mn_ic">
                                  <Image
                                    src="/icons/launchpad.svg"
                                    alt="Pool"
                                    height={'20px'}
                                    width={'19px'}
                                  />
                                </div>
                                Launch
                              </a>
                            </Link>
                          </li>

                          {/* Rewards */}

                          <li>
                            <Link href="/rewards">
                              <a className={router.pathname == '/rewards' ? 'rwrdmn_ic active' : 'rwrdmn_ic'} onClick={() => setOpen(false)}>
                                <div className="mn_ic">
                                  <Image src="/icons/reward_ic.svg" alt="Reward" height={'20px'} width={'19px'} />
                                </div>
                                Rewards
                              </a>
                            </Link>
                          </li>

                          {/* Ice Cream Zombies */}

                          <li>
                            <Link href="https://zombieclub.swapsicle.io/">
                              <a className={'zombiesmn_ic'}>
                                <div className="mn_ic">
                                  <Image src="/icons/ICZC-icon.png" alt="Reward" height={'20px'} width={'19px'} />
                                </div>
                                Ice Cream Zombies
                              </a>
                            </Link>
                          </li>

                        </ul>
                      </nav>

                      <div className="flex flex-col gap-4 px-6">
                        {library && (library.provider.isMetaMask || isCoinbaseWallet) ? (
                          <>
                            {/* <CoinbaseButton account={account} /> */}

                            <div className="hidden sm:flex">
                              <Web3Network />
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              width: '237px',
                              marginRight: '35px',
                            }}
                          ></div>
                        )}
                        <div className="ascrrncslct">
                          <div className="crncy_select_prnt">
                            <Select
                              onChange={async (e) => {
                                let key = e.value
                                console.debug(`Switching to chain ${key}`, SUPPORTED_NETWORKS[key])
                                const params = SUPPORTED_NETWORKS[key]
                                try {
                                  await library?.send('wallet_switchEthereumChain', [
                                    { chainId: `0x${key.toString(16)}` },
                                    account,
                                  ])
                                } catch (switchError) {
                                  // This error code indicates that the chain has not been added to MetaMask.
                                  // @ts-ignore TYPE NEEDS FIXING
                                  // if (switchError.code === 4902) {
                                  try {
                                    await library?.send('wallet_addEthereumChain', [params, account])
                                  } catch (addError) {
                                    // handle "add" error
                                    console.error(`Add chain error ${addError}`)
                                  }
                                  // }
                                  console.error(`Switch chain error ${switchError}`)
                                  // handle other "switch" errors
                                }
                              }}
                              options={options}
                              className="crncy_select"
                              isSearchable={false}
                              classNamePrefix="crncy_select_inn"
                              value={options.filter(e => e.value == chainId)[0]}
                            />
                          </div>
                        </div>

                        <div className="flex items-center justify-start gap-2">
                          <div className="flex items-center w-auto text-sm font-bold border-2 rounded shadow cursor-pointer pointer-events-auto select-none border-dark-800 hover:border-dark-700 bg-dark-900 whitespace-nowrap">
                            {account && chainId && userEthBalance && (
                              <Link href={`/swap`} passHref={true}>
                                <a className="hidden px-3 text-high-emphesis text-bold md:block">
                                  {/*@ts-ignore*/}
                                  {userEthBalance?.toSignificant(4)} {NATIVE[chainId || 1].symbol}
                                </a>
                              </Link>
                            )}
                            <Web3Status />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </header >
    </>
  )
}

export default Mobile
