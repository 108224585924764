import { ChainId, NATIVE } from '@swapsicledex/swapsicle-sdk'
import LanguageSwitch from 'app/components/LanguageSwitch'
import Web3Network from 'app/components/Web3Network'
import Web3Status from 'app/components/Web3Status'
import useIsCoinbaseWallet from 'app/hooks/useIsCoinbaseWallet'
import { useActiveWeb3React } from 'app/services/web3'
import { useETHBalances } from 'app/state/wallet/hooks'
import Image from 'next/image'
import Link from 'next/link'
import React, { FC, Fragment, useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { useRouter } from 'next/router'

import { NavigationItem } from './NavigationItem'
//import CoinbaseButton from '../CoinbasePay'

const Mobile: FC = () => {
  // const menu = useMenu()
  const { account, chainId, library } = useActiveWeb3React()
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const [open, setOpen] = useState(false)
  const isCoinbaseWallet = useIsCoinbaseWallet()
  const router = useRouter()

  const perpURL = "https://pro.swapsicle.io"
  var perpURLChain = chainId == ChainId.BSC ? "?chain=bnb" : chainId == ChainId.ETHEREUM ? "?chain=eth" : chainId == ChainId.ARBITRUM ? "?chain=arb" : ""

  let menu = ''
  if (
    router.asPath.startsWith('/pool') ||
    router.asPath.startsWith('/add/ETH') ||
    router.asPath.startsWith('/find') ||
    router.asPath.startsWith('/remove')
  ) {
    menu = 'pool'
  } else if (router.asPath.startsWith('/farm')) {
    menu = 'farm'
  } else if (router.asPath.startsWith('/ice-box')) {
    menu = 'ice-box'
  } else if (router.asPath.startsWith('/ice-cream-van')) {
    menu = 'ice-cream-van'
  } else if (router.asPath.startsWith('/ice-vault')) {
    menu = 'ice-vault'
  } else if (router.asPath.startsWith('/cross-chain')) {
    menu = 'cross-chain'
  } else if (router.asPath.startsWith('/leaderboard')) {
    menu = 'pro'
  } else if (router.asPath.startsWith('/swap')) {
    menu = 'swap'
  }

  return (
    <>
      <header className="w-full flex items-center justify-between min-h-[64px] h-[64px] px-4 z-9999 mn_header">
        <div className="flex justify-between flex-grow">
          <div className="p-2 rounded-full hover:bg-white/10">
            <div className="fixed inset-y-0 left-0 max-w-[260px] flex">
              <div className="w-screen max-w-sm">
                <div className="fullNav h-full flex flex-col py-6 bg-[#131928] shadow-xl overflow-x-hidden">
                  <nav className="navbar flex-1 pl-6 justify-content-center bg-[#131928]" aria-label="Sidebar">
                    {/* <div className="flex mr-1" style={{"height":"45px"}}> */}
                    <div style={{ width: '100%', height: '34px', position: 'relative' }} className="mn_logo">
                      <Link href="/" passHref={true}>
                        <Image
                          src="/images/logo-light.svg"
                          alt="Swapsicle logo"
                          layout="fill"
                          objectFit="contain"
                          objectPosition="left"
                        />
                        {/* <Image src="/images/logo-light.png" alt="Swapsicle logo" width="164.26px" height="34px" /> */}
                      </Link>
                    </div>
                    {/* </div> */}
                    {/* {menu.map((node) => {
                      return <NavigationItem node={node} key={node.key} />
                    })} */}
                    <ul className="main_sd_mn">

                      {/* Swap */}

                      <li>
                        <Disclosure defaultOpen={menu == 'swap' ? true : false}>
                          <Disclosure.Button className="drpdwn_mn_btn">
                            <a className={menu == 'swap' ? 'swap_ic active' : 'swap_ic'}>
                              <div className="mn_ic">
                                <Image src="/icons/swap_ic.svg" alt="Swap" height={'16px'} width={'17px'} />
                              </div>
                              Swap
                            </a>

                            <ChevronDownIcon className="mn_hndlr" />
                          </Disclosure.Button>
                          <Disclosure.Panel className="mainlst">
                            <div className="inner_mn_list inner_mn_list_swap">
                              <Link href="/swap">
                                <a className={router.asPath == '/swap' ? 'active' : ''}>Crypto</a>
                              </Link>
                              <Link href="/swap/fiat">
                                <a className={router.asPath == '/swap/fiat' ? 'active' : ''}>Fiat</a>
                              </Link>
                            </div>
                          </Disclosure.Panel>
                        </Disclosure>
                      </li>

                      {/* Perpetuals / Pro */}

                      {/* <li>
                        <Link href={perpURL}>
                          <a className='pro_ic'>
                            <div className="mn_ic">
                              <Image src="/icons/Pro_ic2.svg" alt="Perpetuals" height={'16px'} width={'17px'} />
                            </div>
                            <p>Swapsicle Pro</p>
                          </a>
                        </Link>
                      </li> */}

                      <li>
                        <Disclosure defaultOpen={menu == 'pro' ? true : false}>
                          <Disclosure.Button className="drpdwn_mn_btn">
                            <a className={menu == 'pro' ? 'pro_ic active' : 'pro_ic'}>
                              <div className="mn_ic">
                                <Image src="/icons/Pro_ic2.svg" alt="Perpetuals" height={'16px'} width={'17px'} />
                              </div>
                              <p>Swapsicle Pro</p>
                            </a>
                            <ChevronDownIcon className="mn_hndlr" />
                          </Disclosure.Button>

                          <Disclosure.Panel className="mainlst">
                            <div className="inner_mn_list inner_mn_list_pro">
                              <Link href={perpURL}>
                                <a className={router.asPath == '/pro' ? 'active' : ''}><p>Trade</p></a>
                              </Link>
                              <Link href="/leaderboard">
                                <a className={router.asPath == '/leaderboard' ? 'active' : ''}>Pro Rewards</a>
                              </Link>
                            </div>
                          </Disclosure.Panel>
                        </Disclosure>
                      </li>

                      {/* Cross-Chain  */}

                      {/* <li>
                        <Disclosure defaultOpen={menu == 'cross-chain' ? true : false}>
                          <Disclosure.Button className="drpdwn_mn_btn">
                            <a className={menu == 'cross-chain' ? 'crosschain_ic active' : 'crosschain_ic'}>
                              <div className="mn_ic">
                                <Image src="/icons/bridge-icon-red.svg" alt="Cross-Chain" height={'21px'} width={'21px'} />
                              </div>
                              Cross-Chain
                            </a>

                            <ChevronDownIcon className="mn_hndlr" />
                          </Disclosure.Button>
                          <Disclosure.Panel className="mainlst">
                            <div className="inner_mn_list inner_mn_list_bridge">
                              <Link href="https://app.multichain.org/#/router">
                                <a className={router.asPath == '/cross-chain/bridge' ? 'active' : ''}>Bridge</a>
                              </Link>
                            </div>
                          </Disclosure.Panel>
                        </Disclosure>
                      </li> */}

                      {/* Pool  */}

                      <li>
                        <Disclosure defaultOpen={menu == 'pool' ? true : false}>
                          <Disclosure.Button className="drpdwn_mn_btn">
                            <a className={menu == 'pool' ? 'pll_ic active' : 'pll_ic'}>
                              <div className="mn_ic">
                                <Image src="/icons/pool_m_ic.svg" alt="Pool" height={'21px'} width={'21px'} />
                              </div>
                              Pool
                            </a>

                            <ChevronDownIcon className="mn_hndlr" />
                          </Disclosure.Button>
                          <Disclosure.Panel className="mainlst">
                            <div className="inner_mn_list inner_mn_list_pools">
                              <Link href="/browse">
                                <a className={router.asPath == '/browse' ? 'active' : ''}>Browse</a>
                              </Link>
                              <Link href="/pool">
                                <a className={router.asPath == '/pool' ? 'active' : ''}>My Pools</a>
                              </Link>
                              <Link href="/add/ETH/0x240248628B7B6850352764C5dFa50D1592A033A8">
                                <a className={router.asPath.startsWith('/add/ETH/') ? 'active' : ''}>Create</a>
                              </Link>
                              <Link href="/find">
                                <a className={router.asPath == '/find' ? 'active' : ''}>Import</a>
                              </Link>
                            </div>
                          </Disclosure.Panel>
                        </Disclosure>
                      </li>



                      {/* Farms  */}

                      {chainId === ChainId.AVALANCHE || chainId === ChainId.TELOS_TESTNET || chainId === ChainId.TELOS ? (
                        <li>
                          <Disclosure defaultOpen={menu == 'farm' ? true : false}>
                            <Disclosure.Button className="drpdwn_mn_btn">
                              <a className={menu == 'farm' ? 'farm_ic  active' : 'farm_ic'}>
                                <div className="mn_ic">
                                  <Image src="/icons/farn_ic.svg" alt="Farm" height={'20px'} width={'20px'} />
                                </div>
                                Farm
                              </a>

                              <ChevronDownIcon className="mn_hndlr" />
                            </Disclosure.Button>
                            <Disclosure.Panel className="mainlst">
                              <div className="inner_mn_list inner_mn_list_farms">
                                <Link href="/farm">
                                  <a className={router.asPath == '/farm' ? 'active' : ''}>All Farms</a>
                                </Link>
                                <Link href="/farm?filter=portfolio">
                                  <a className={router.asPath.startsWith('/farm?filter=portfolio') ? 'active' : ''}>
                                    My Farms
                                  </a>
                                </Link>
                              </div>
                            </Disclosure.Panel>
                          </Disclosure>
                        </li>
                      ) : (
                        <></>
                      )}

                      {/* Stake */}

                      {/* {chainId == ChainId.AVALANCHE ? ( */}
                      <li>
                        <Disclosure
                          defaultOpen={
                            menu == 'ice-cream-van' || menu == 'ice-box' || menu == 'ice-vault' ? true : false
                          }
                        >
                          <Disclosure.Button className="drpdwn_mn_btn">
                            {/* <a className={menu == 'stake' ? 'stake_ic active' : 'stake_ic'}> */}
                            <a
                              className={
                                menu == 'ice-box'
                                  ? 'ice_box active'
                                  : menu === 'ice-cream-van'
                                    ? 'ice_van active'
                                    : menu === 'ice-vault'
                                      ? 'ice_vault active'
                                      : 'stake_ic'
                              }
                            >
                              <div className="mn_ic">
                                {menu == 'ice-box' ? (
                                  <Image src="/icons/stake_box.svg" alt="IceBox" height={'20px'} width={'20px'} />
                                ) : menu === 'ice-cream-van' ? (
                                  <Image src="/icons/stake_van.svg" alt="Van" height={'20px'} width={'20px'} />
                                ) : menu === 'ice-vault' ? (
                                  <Image src="/icons/stake_vault.svg" alt="Vault" height={'20px'} width={'20px'} />
                                ) : (
                                  <Image src="/icons/stake.svg" alt="Farm" height={'20px'} width={'20px'} />
                                )}
                              </div>
                              Stake
                            </a>
                            <ChevronDownIcon className="mn_hndlr" />
                          </Disclosure.Button>
                          <Disclosure.Panel className="mainlst">
                            <div className="inner_mn_list inner_mn_list_stake">

                              <Link href="/ice-cream-van">
                                <a className={menu === 'ice-cream-van' ? 'ice_van active' : 'ice_van'}>
                                  Ice Cream Van
                                </a>
                              </Link>

                              <Link href="/ice-box">
                                <a className={menu === 'ice-box' ? 'ice_box active' : 'ice_box'}>IceBox</a>
                              </Link>
                              <Link href="/ice-vault">
                                <a className={menu === 'ice-vault' ? 'ice_vault active' : 'ice_vault'}>IceVault</a>
                              </Link>
                            </div>
                          </Disclosure.Panel>
                        </Disclosure>
                      </li>
                      {/* ) : (
                      <></>
                      )} */}

                      {/* Launchpad */}

                      <li>
                        <Link href="/launchpad">
                          <a className={router.pathname == '/launchpad' ? 'lp_ic active' : 'lp_ic'}>
                            <div className="mn_ic">
                              <Image
                                src="/icons/launchpad.svg"
                                alt="Launchpad"
                                height={'20px'}
                                width={'19px'}
                              />
                            </div>
                            Launch
                          </a>
                        </Link>
                      </li>

                      {/* Ice Cream Time  */}
                      {/* <li>
                        <Link href="/ice-cream-time">
                          <a className={router.pathname == '/ice-cream-time' ? 'icecrmtime_ic active' : 'icecrmtime_ic'}>
                            <div className="mn_ic">
                              <Image
                                src="/icons/ice_cream_time_ic.svg"
                                alt="icecreamtime"
                                height={'20px'}
                                width={'19px'}
                              />
                            </div>
                            Ice Cream Time
                          </a>
                        </Link>
                      </li> */}

                      {/* Rewards */}

                      <li>
                        <Link href="/rewards">
                          <a className={router.pathname == '/rewards' ? 'rwrdmn_ic active' : 'rwrdmn_ic'}>
                            <div className="mn_ic">
                              <Image src="/icons/reward_ic.svg" alt="Reward" height={'20px'} width={'19px'} />
                            </div>
                            Rewards
                          </a>
                        </Link>
                      </li>

                      {/* Ice Cream Zombies */}

                      <li>
                        <Link href="https://zombieclub.swapsicle.io/">
                          <a className={'zombiesmn_ic'}>
                            <div className="mn_ic">
                              <Image src="/icons/ICZC-icon.png" alt="Reward" height={'20px'} width={'19px'} />
                            </div>
                            Ice Cream Zombies
                          </a>
                        </Link>
                      </li>

                      {/* Portfolio */}

                      {/* <li>
                        <Link href={`/portfolio/${account ? account : 'no-account'}`}>
                          <a className={router.asPath.startsWith('/portfolio') ? 'prtfl_ic active' : 'prtfl_ic'}>
                            <div className="mn_ic">
                              <Image src="/icons/portfolio_ic.svg" alt="portfolio" height={'22px'} width={'15px'} />
                            </div>
                            Portfolio
                          </a>
                        </Link>
                      </li> */}

                    </ul>
                  </nav>

                  <div className="hidden lg:flex z-index-[-1]">
                    <LanguageSwitch />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="" style={{ display: 'flex' }}>
            {/* {account ? <CoinbaseButton account={account} /> : <></>} */}

            <div className="flex flex-col gap-4 px-6">
              <div
                style={{
                  background:
                    'linear-gradient(#131928 0 0) padding-box,linear-gradient(135deg, #5AF9C4 0%, #3D5DFF 50%, #FF73E1 100%) border-box',
                  border: '2px solid transparent',
                  borderRadius: '10px',
                }}
                className="flex items-center justify-start gap-2"
              >
                <div className="flex items-center w-auto text-sm font-bold border-2 rounded shadow cursor-pointer pointer-events-auto select-none border-dark-800 hover:border-dark-700 bg-dark-900 whitespace-nowrap">
                  <Web3Network />

                  {/* {library && (library.provider.isMetaMask || isCoinbaseWallet) && (
                    <div className="">
                      <Web3Network />
                    </div>
                  )} */}
                  {account && chainId && userEthBalance && (
                    <Link href={`/swap`} passHref={true}>
                      <a className="hidden px-3 text-high-emphesis text-bold md:block">
                        {userEthBalance?.toSignificant(4)} {NATIVE[chainId || 1].symbol}
                      </a>
                    </Link>
                  )}
                  <Web3Status />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Mobile
