import { useRouter } from 'next/router'
import Footer from 'app/components/Footer'
import Header from 'app/components/Header'
import LandingHeader from 'app/components/LandingHeader'
import Main from 'app/components/Main'
import Popups from 'app/components/Popups'

// @ts-ignore TYPE NEEDS FIXING
const Layout = ({ children }) => {
  const router = useRouter()

  return (
    // <div className="z-0 flex flex-col items-center w-full h-screen">
    <div className="z-0 flex flex-col items-center w-full h-screen">
      {router.pathname === '/' ? <LandingHeader /> : <Header />}
      <Main>{children}</Main>
      <Popups />
      <Footer />
    </div>
  )
}

export default Layout
