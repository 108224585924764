import { ADDITIONAL_BASES, BASES_TO_CHECK_TRADES_AGAINST, Currency, getAllCurrencyCombinations, Token } from '@swapsicledex/swapsicle-sdk'
import { useActiveWeb3React } from 'app/services/web3'
import flatMap from 'lodash/flatMap'
import { useMemo } from 'react'

export function useAllCurrencyCombinations(currencyA?: Currency, currencyB?: Currency): [Token, Token][] {
  const { chainId } = useActiveWeb3React()

  const [tokenA, tokenB] = chainId ? [currencyA?.wrapped, currencyB?.wrapped] : [undefined, undefined]

  const bases: Token[] = useMemo(() => {
    if (!chainId) return []

    const common = BASES_TO_CHECK_TRADES_AGAINST[chainId] ?? []
    const additionalA = tokenA ? ADDITIONAL_BASES[chainId]?.[tokenA.address] ?? [] : []
    const additionalB = tokenB ? ADDITIONAL_BASES[chainId]?.[tokenB.address] ?? [] : []

    return [...common, ...additionalA, ...additionalB]
  }, [chainId, tokenA, tokenB])

  const basePairs: [Token, Token][] = useMemo(
    () => flatMap(bases, (base): [Token, Token][] => bases.map((otherBase) => [base, otherBase])),
    [bases]
  )

  return useMemo(
    () => getAllCurrencyCombinations(chainId, bases, basePairs, tokenA, tokenB),
    [chainId, bases, basePairs, tokenA, tokenB]
  )
}
