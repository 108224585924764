import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
// import { DiscordIcon, InstagramIcon, MediumIcon, TwitterIcon } from 'app/components/Icon'
import { DiscordIcon, InstagramIcon, TelegramIcon, LinkedinIcon, YoutubeIcon, TwitterIcon, MediumIcon, GithubIcon } from 'app/components/Icon'
import Typography from 'app/components/Typography'
import { useActiveWeb3React } from 'app/services/web3'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

import Container from '../Container'

const Footer = () => {
  const { chainId } = useActiveWeb3React()
  const { i18n } = useLingui()

  return (
    <div className="z-10 w-full footerMain">
      <Container className="px-6 mx-f100">
        <div className="grid_cod_raw">
          <div className="girid_col border_top">
            <div className="flex items-center justify-start gap-2 mrgn_btm_14">
              <div className="">
                <Image src="/images/Swapsicle-Icon.png" alt="Swapsicle logo" width="28px" height="28px" />
              </div>
              {/* <Typography variant="h2" weight={700} className="tracking-[0.02em] scale-y-90 hover:text-high-emphesis">
                SwapSicle
              </Typography> */}
            </div>
            <Typography variant="xs" className="footer_p footer_p_frst">
              {i18n._(
                t`DeFi with more flavour. Securely store, manage & swap your crypto all in one place.`
              )}
            </Typography>
            <div className="flex items-center gap-4 scal_link_prnt">
              <a href="https://twitter.com/SwapsicleDEX" target="_blank" rel="noreferrer">
                <TwitterIcon width={16} className="text-low-emphesis" />
              </a>
              {/* <a href="https://instagram.com/swapsicledex?utm_medium=copy_link" target="_blank" rel="noreferrer">
                <InstagramIcon width={16} className="text-low-emphesis" />
              </a> */}
              <a href="https://medium.com/@swapsicle" target="_blank" rel="noreferrer">
                <MediumIcon width={16} className="text-low-emphesis" />
              </a>
              <a href="https://discord.gg/6UxVtUS4F8" target="_blank" rel="noreferrer">
                <DiscordIcon width={16} className="text-low-emphesis" />
              </a>
              <a href="https://www.youtube.com/channel/UCbi1Iwp3s01hZoqRWEX0OiA" target="_blank" rel="noreferrer">
                <YoutubeIcon width={16} className="text-low-emphesis" />
              </a>
              <a href="https://t.me/SwapsicleDEX" target="_blank" rel="noreferrer">
                <TelegramIcon width={16} className="text-low-emphesis" />
              </a>
              <a href="https://t.me/SwapsicleDAO" target="_blank" rel="noreferrer">
                <TelegramIcon width={16} className="text-low-emphesis" />
              </a>
              <a href="https://www.linkedin.com/company/swapsicle/" target="_blank" rel="noreferrer">
                <LinkedinIcon width={16} className="text-low-emphesis" />
              </a>
            </div>
          </div>
          <div className="girid_col border_top">
            <div className="ftr_abt_prdct_prnt">
              <div className="ftr_about_us">
                <Typography variant="xs" weight={700} className="mt-2.5 hover:text-high-emphesis foter_h1">
                  {'About Us'}
                </Typography>
                <a href="https://docs.swapsicle.io" target="_blank" rel="noreferrer">
                  <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis footer_p">
                    {i18n._(t`Docs`)}
                  </Typography>
                </a>
                <a href="https://coinmarketcap.com/currencies/swapsicle/" target="_blank" rel="noreferrer">
                  <Typography variant="xs" className=" hover:text-high-emphesis footer_p">
                    {/* <Image src="/images/gecko.svg" alt="Mail" width="28px" height="28px" />
                    &nbsp; */}
                    <span>CoinMarketCap</span>
                  </Typography>
                </a>
                <a href="https://www.certik.com/projects/swapsicle" target="_blank" rel="noreferrer">
                  <Typography variant="xs" className=" hover:text-high-emphesis footer_p">
                    {/* <Image src="/images/gecko.svg" alt="Mail" width="28px" height="28px" />
                    &nbsp; */}
                    <span>Certik Audit</span>
                  </Typography>
                </a>
                <a href="https://github.com/swapsicledex" target="_blank" rel="noreferrer">
                  <Typography variant="xs" className=" hover:text-high-emphesis footer_p">
                    {/* <Image src="/images/gecko.svg" alt="Mail" width="28px" height="28px" />
                    &nbsp; */}
                    <span>Github</span>
                  </Typography>
                </a>
              </div>
              <div className="ftr_our_prdct">
                <Typography variant="xs" weight={700} className="mt-2.5 hover:text-high-emphesis foter_h1">
                  {i18n._(t`Our products`)}
                </Typography>
                <Link href="/swap" passHref={true}>
                  <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis footer_p">
                    {i18n._(t`Swap`)}
                  </Typography>
                </Link>
                <Link href={'/browse'} passHref={true}>
                  <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis footer_p">
                    {i18n._(t`Liquidity Pools`)}
                  </Typography>
                </Link>
                <Link href="/farm" passHref={true}>
                  <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis footer_p">
                    {i18n._(t`Farms`)}
                  </Typography>
                </Link>
                {/* <Link href={'/cross-chain/bridge'} passHref={true}>
                  <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis footer_p">
                    {i18n._(t`Bridge`)}
                  </Typography>
                </Link> */}
                {/* <Link href={'https://app.multichain.org/#/router'} passHref={true}>
                  <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis footer_p">
                    {i18n._(t`Bridge`)}
                  </Typography>
                </Link> */}
                <Link href="/ice-cream-van" passHref={true}>
                  <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis footer_p">
                    {i18n._(t`Stake`)}
                  </Typography>
                </Link>
                {/* <Link href="/ice-cream-time" passHref={true}>
                  <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis footer_p">
                    {i18n._(t`Ice Cream Time`)}
                  </Typography>
                </Link> */}
                <Link href="/rewards" passHref={true}>
                  <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis footer_p">
                    {i18n._(t`Rewards`)}
                  </Typography>
                </Link>
              </div>
            </div>
          </div>
          <div className="girid_col border_top">
            <div className="ftr_sprt_prnt">
              <div>
                <Typography variant="xs" weight={700} className="mt-2.5 hover:text-high-emphesis foter_h1">
                  {i18n._(t`Contact us`)}
                </Typography>
                <a href="mailto:admin@swapsicle.io">
                  <Typography variant="xs" className="mt-2.5 hover:text-high-emphesis footer_p">
                    {/* <Image src="/images/mail.svg" alt="Mail" width="28px" height="28px" />
                    &nbsp; */}
                    <span>Email</span>
                  </Typography>
                </a>
                <a href="https://twitter.com/SwapsicleDEX" target="_blank" rel="noreferrer">
                  <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis footer_p">
                    {i18n._(t`Twitter`)}
                  </Typography>
                </a>
                <a href="https://discord.gg/6UxVtUS4F8" target="_blank" rel="noreferrer">
                  <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis footer_p">
                    {i18n._(t`Discord`)}
                  </Typography>
                </a>
                <a href="https://t.me/SwapsicleDAO" target="_blank" rel="noreferrer">
                  <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis footer_p">
                    {i18n._(t`Telegram`)}
                  </Typography>
                </a>
                {/* <a href="https://www.youtube.com/channel/UCbi1Iwp3s01hZoqRWEX0OiA" target="_blank" rel="noreferrer">
                  <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis footer_p">
                    {i18n._(t`YouTube`)}
                  </Typography>
                </a>
                <a href="https://medium.com/@swapsicle" target="_blank" rel="noreferrer">
                  <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis footer_p">
                    {i18n._(t`Medium`)}
                  </Typography>
                </a>
                <a href="https://t.me/SwapsicleDEX" target="_blank" rel="noreferrer">
                  <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis footer_p">
                    {i18n._(t`Telegram`)}
                  </Typography>
                </a> */}
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col gap-1 text-left">
            <Typography variant="xs" weight={700} className="mt-2.5 hover:text-high-emphesis">
              {i18n._(t`Developers`)}
            </Typography>
            <a href="#" target="_blank" rel="noreferrer">
              <Typography variant="xs" className="text-low-emphesis hover:text-high-emphesis">
                {i18n._(t`GitHub`)}
              </Typography>
            </a>
          </div> */}
          
        </div>
      </Container>
    </div>
  )
}

export default Footer
