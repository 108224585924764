import { ChainId } from '@swapsicledex/swapsicle-sdk'
import { GRAPH_HOST } from 'app/services/graph/constants'
import { getTokenSubset } from 'app/services/graph/fetchers/exchange'
import {
  masterChefV1PairAddressesQuery,
  masterChefV1SushiPerBlockQuery,
  masterChefV1TotalAllocPointQuery,
  masterChefV2PairAddressesQuery,
  miniChefPairAddressesQuery,
  miniChefPoolsQuery,
  poolsQuery,
  poolsV2Query,
  masterChefV2PoolsQuery,
} from 'app/services/graph/queries'
import { request } from 'graphql-request'

export const MINICHEF = {
  [ChainId.MATIC]: 'sushiswap/matic-minichef',
  [ChainId.XDAI]: 'sushiswap/xdai-minichef',
  [ChainId.HARMONY]: 'sushiswap/harmony-minichef',
  [ChainId.ARBITRUM]: 'matthewlilley/arbitrum-minichef',
  [ChainId.CELO]: 'sushiswap/celo-minichef-v2',
  [ChainId.MOONRIVER]: 'sushiswap/moonriver-minichef',
  [ChainId.FUSE]: 'sushiswap/fuse-minichef',
  [ChainId.FANTOM]: 'sushiswap/fantom-minichef',
  [ChainId.MOONBEAM]: 'sushiswap/moonbeam-minichef',
}

export const OLD_MINICHEF = {
  [ChainId.CELO]: 'sushiswap/celo-minichef',
}

// @ts-ignore TYPE NEEDS FIXING
export const miniChef = async (query, chainId = ChainId.ETHEREUM, variables = undefined) =>
  // @ts-ignore TYPE NEEDS FIXING
  request(`${GRAPH_HOST[chainId]}/subgraphs/name/${MINICHEF[chainId]}`, query, variables)

// @ts-ignore TYPE NEEDS FIXING
export const oldMiniChef = async (query, chainId = ChainId.ETHEREUM) =>
  // @ts-ignore TYPE NEEDS FIXING
  request(`${GRAPH_HOST[chainId]}/subgraphs/name/${OLD_MINICHEF[chainId]}`, query)

export const MASTERCHEF_V2 = {
  [ChainId.TELOS_TESTNET]: 'swapsicledex/swapsicle-masterchefv2-telos-testnet',
  [ChainId.TELOS]: 'swapsicledex/swapsicle-masterchefv2-telos',
  [ChainId.AVALANCHE]: 'swapsicledex/swapsicle-masterchefv2',
}

export const MASTERCHEF_V2_DUELREWARD = {
  [ChainId.TELOS_TESTNET]: 'swapsicledex/swapsicle-masterchefv2-duel-telos-testnet',
  [ChainId.TELOS]: 'swapsicledex/swapsicle-masterchefv2-duel-telos',
}

export const MASTERCHEF_V2_DUELREWARD_FORTIS = {
  [ChainId.TELOS_TESTNET]: 'swapsicledex/swapsicle-masterchefv2-duel-telos-testnet-fortis',
  [ChainId.TELOS]: 'swapsicledex/swapsicle-masterchefv2-duel-telos-fortis',
}

// @ts-ignore TYPE NEEDS FIXING
export const masterChefV2DuelReward = async (query, chainId = ChainId.TELOS, variables = undefined) =>
  // @ts-ignore TYPE NEEDS FIXING
  request(
    `${chainId == ChainId.TELOS ? GRAPH_HOST[0] : GRAPH_HOST[chainId]}/subgraphs/name/${
      MASTERCHEF_V2_DUELREWARD[chainId]
    }`,
    query,
    variables
  )

// @ts-ignore TYPE NEEDS FIXING
export const masterChefV2DuelRewardFortis = async (query, chainId = ChainId.TELOS, variables = undefined) =>
  // @ts-ignore TYPE NEEDS FIXING
  request(
    `${chainId == ChainId.TELOS ? GRAPH_HOST[0] : GRAPH_HOST[chainId]}/subgraphs/name/${
      MASTERCHEF_V2_DUELREWARD_FORTIS[chainId]
    }`,
    query,
    variables
  )

// // @ts-ignore TYPE NEEDS FIXING
// export const masterChefV2DuelReward = async (query, chainId = ChainId.TELOS, variables = undefined) => {
//   const urls = MASTERCHEF_V2_DUELREWARD[chainId]
//   const results = []

//   for (const url of urls) {
//     const result = await request(`${GRAPH_HOST[chainId]}/subgraphs/name/${url}`, query, variables)
//     results.push(result)
//   }

//   return results.flatMap((res) => res.pools)
// }

// @ts-ignore TYPE NEEDS FIXING
export const masterChefV2 = async (query, chainId = ChainId.TELOS, variables = undefined) =>
  // @ts-ignore TYPE NEEDS FIXING
  request(`${GRAPH_HOST[chainId]}/subgraphs/name/${MASTERCHEF_V2[chainId]}`, query, variables)

export const MASTERCHEF_V1 = {
  [ChainId.ETHEREUM]: 'sushiswap/master-chef',

  // [ChainId.AVALANCHE]: 'bitgraphix/swapsicle-master-chef',
  [ChainId.AVALANCHE]: 'swapsicledex/swapsicle-masterchef',
}

// @ts-ignore TYPE NEEDS FIXING
export const masterChefV1 = async (query, chainId = ChainId.AVALANCHE, variables = undefined) =>
  // @ts-ignore TYPE NEEDS FIXING
  request(`${GRAPH_HOST[chainId]}/subgraphs/name/${MASTERCHEF_V1[chainId]}`, query, variables)

export const getMasterChefV1TotalAllocPoint = async () => {
  const {
    masterChef: { totalAllocPoint },
  } = await masterChefV1(masterChefV1TotalAllocPointQuery)
  return totalAllocPoint
}

export const getMasterChefV1SushiPerBlock = async () => {
  const {
    masterChef: { sushiPerBlock },
  } = await masterChefV1(masterChefV1SushiPerBlockQuery)
  return sushiPerBlock / 1e18
}

export const getMasterChefV1Farms = async (variables = undefined) => {
  const { pools } = await masterChefV1(poolsQuery, ChainId.AVALANCHE, variables)
  return pools
}

export const getMasterChefV1PairAddreses = async () => {
  const { pools } = await masterChefV1(masterChefV1PairAddressesQuery)
  return pools
}

export const getMasterChefV2Farms = async (variables = undefined, chainId = ChainId.TELOS) => {
  const { pools } = await masterChefV2(poolsV2Query, chainId, variables)
  return pools
}

export const getMasterChefV2DuelRewardFarms = async (variables = undefined, chainId = ChainId.TELOS) => {
  const { pools } = await masterChefV2DuelReward(poolsV2Query, chainId, variables)
  return pools
}

export const getMasterChefV2DuelRewardFarmsFortis = async (variables = undefined, chainId = ChainId.TELOS) => {
  const { pools } = await masterChefV2DuelRewardFortis(poolsV2Query, chainId, variables)
  return pools
}

export const getMasterChefV2PairAddreses = async () => {
  const { pools } = await masterChefV1(masterChefV1PairAddressesQuery)
  return pools?.map((pool) => pool.pair)
}

export const getOldMiniChefFarms = async (chainId = ChainId.ETHEREUM) => {
  const { pools } = await oldMiniChef(miniChefPoolsQuery, chainId)
  return pools
}

export const getMiniChefFarms = async (chainId = ChainId.ETHEREUM, variables = undefined) => {
  const { pools } = await miniChef(miniChefPoolsQuery, chainId, variables)
  return pools
}

export const getMiniChefPairAddreses = async (chainId = ChainId.ETHEREUM) => {
  console.debug('getMiniChefPairAddreses')
  const { pools } = await miniChef(miniChefPairAddressesQuery, chainId)
  return pools
}
