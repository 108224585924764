import { ChainId } from '@swapsicledex/swapsicle-sdk'

const THE_GRAPH = 'https://api.thegraph.com'
const SWAPSICLE_SELFHOST_TELOS_TESTNET = 'https://testnet.telos.subgraph.swapsicle.io'
const SWAPSICLE_SELFHOST_TELOS = 'https://telos.subgraph.swapsicle.io'
const SWAPSICLE_SELFHOST_TELOS_BACKUP = 'https://telos-v1.subgraph.swapsicle.io'
const NAS_GRAPH = 'https://graph.kkt.one/node'
const HYPER_GRAPH = 'https://q.hg.network'

export const GRAPH_HOST = {
  [ChainId.ETHEREUM]: THE_GRAPH,
  [ChainId.XDAI]: THE_GRAPH,
  [ChainId.MATIC]: THE_GRAPH,
  [ChainId.FANTOM]: THE_GRAPH,
  [ChainId.BSC]: THE_GRAPH,
  [ChainId.AVALANCHE]: THE_GRAPH,
  [ChainId.CELO]: THE_GRAPH,
  [ChainId.ARBITRUM]: THE_GRAPH,
  [ChainId.OPTIMISM]: THE_GRAPH,
  [ChainId.HARMONY]: 'https://sushi.graph.t.hmny.io',
  [ChainId.OKEX]: HYPER_GRAPH,
  [ChainId.HECO]: HYPER_GRAPH,
  [ChainId.MOONRIVER]: THE_GRAPH,
  [ChainId.TELOS]: SWAPSICLE_SELFHOST_TELOS,
  [0]: SWAPSICLE_SELFHOST_TELOS_BACKUP,
  [ChainId.TELOS_TESTNET]: SWAPSICLE_SELFHOST_TELOS_TESTNET,
  [ChainId.KOVAN]: THE_GRAPH,
  [ChainId.FUSE]: THE_GRAPH,
  [ChainId.MOONBEAM]: THE_GRAPH,
}

export const TRIDENT = {
  [ChainId.MATIC]: 'sushiswap/trident-polygon',
  [ChainId.KOVAN]: 'sushiswap/trident-kovan',
}
