import { ChainId } from '@swapsicledex/swapsicle-sdk'
import { GRAPH_HOST } from 'app/services/graph/constants'
import { barHistoriesQuery, barQuery } from 'app/services/graph/queries/bar'
import { request } from 'graphql-request'
import { BarStack } from '@visx/shape'

const BAR = {
  [ChainId.ETHEREUM]: 'matthewlilley/bar',
  [ChainId.AVALANCHE]: 'swapsicledex/swapsicle-bar',
  [ChainId.TELOS]: 'swapsicledex/swapsicle-bar-telos',
  [ChainId.TELOS_TESTNET]: 'swapsicledex/swapsicle-bar-telos-testnet',
}

// @ts-ignore TYPE NEEDS FIXING
const fetcher = async (query, chainId = ChainId.AVALANCHE, variables = undefined) =>
  request(
    `${chainId == ChainId.TELOS ? GRAPH_HOST[0] : GRAPH_HOST[chainId]}/subgraphs/name/${BAR[chainId]}`,
    query,
    variables
  )

export const getBar = async (variables = undefined, chainId = ChainId.AVALANCHE) => {
  const { bars } = await fetcher(barQuery, chainId, variables)
  return bars
}

export const getBarHistory = async (variables = undefined, chainId = ChainId.AVALANCHE) => {
  const { histories } = await fetcher(barHistoriesQuery, chainId, variables)
  return histories
}
