import { ChainId } from '@swapsicledex/swapsicle-sdk'
import { GRAPH_HOST } from 'app/services/graph/constants'
import { getTokenSubset } from 'app/services/graph/fetchers/exchange'
import { burnsQuery } from 'app/services/graph/queries'
import { request } from 'graphql-request'

// @ts-ignore TYPE NEEDS FIXING
export const burn = async (query, chainId = ChainId.ETHEREUM, variables = undefined) =>
  // @ts-ignore TYPE NEEDS FIXING
  request(`${GRAPH_HOST[chainId]}/subgraphs/name/swapsicledex/swapsicle-burn`, query, variables)

export const getBurn = async (variables = undefined) => {
  const { burns } = await burn(burnsQuery, ChainId.AVALANCHE, variables)
  return burns
}
